import React from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import {motion} from 'framer-motion'

import defaultBlogImage from '../../static/blog_post.gif';
import { Card, CardContent, CardMedia, Typography } from '@material-ui/core';
import { navItem, navList } from '../utils/animationVariants';

const RecentBlogs = ({ location }) => {
	const data = useStaticQuery(graphql`
		query {
			allContentfulBlogPost {
				edges {
					node {
						title
						imageUrl{
							file{
								url
								fileName
							}
						}
						slug
						publishedDate(formatString: "MMM Do YYY")
					}
				}
			}
		}
	`);
	const blogsData = data.allContentfulBlogPost.edges.map(({ node: { slug, title, publishedDate, imageUrl } }) => ({
		title,
		imageURL:imageUrl?.file?.url,
		imageName: imageUrl?.file?.fileName,
		blogURL: `/blogs/${slug}`,
		publishedDate,
		body: ''
	}));
	return (
		<motion.div class="recentBlogs" initial="hidden" animate="visible" variants={navList}>
			{blogsData.map((blog, i) => {
				return (
					<motion.div 
						custom={i}
						initial="hidden"
						animate="visible"
						variants={navItem}
						whileHover={{ color: 'darkgreen', x: 10 }}
						whileTap={{ scale:0.9 }}
					>
					<Link to={blog.blogURL} key={i} style={{ textDecoration: 'none' }}>
						<Card className="recentBlog" >
							<div className="recentBlogImg">
								<CardMedia
									component="img"
									alt="Contemplative Reptile"
									// height="140"
									image={blog.imageURL || defaultBlogImage}
									title={blog.imageName}
								/>
							</div>
							<CardContent>
								<Typography variant="Subtitle1" component="p">
									{blog.title}
								</Typography>
								<Typography  variant="caption" color="textSecondary" component="p">
									{blog.publishedDate}
								</Typography>
							</CardContent>
						</Card>
						</Link>
						</motion.div>
				);
			})}
		</motion.div>
	);
};

export default RecentBlogs;
