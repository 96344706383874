import { createGlobalStyle } from 'styled-components';
import variables from './variables';
import swiperStyles from './swiperStyles';

const GlobalStyle = createGlobalStyle`
    ${variables};
    ${swiperStyles};    
  html {
    box-sizing: border-box;
    width: 100%;
  }
  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }
  ::selection {
    background-color: var(--lightest-navy);
    color: var(--lightest-slate);
  }
  :focus {
    /* border-bottom:5px solid var(--green);
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px; */
    /* outline: 2px dashed var(--green);
    outline-offset: 3px; */
  }
  /* Scrollbar Styles */
  html {
    scrollbar-width: thin;
    scrollbar-color: var(--teal-green) var(--light-navy);
  }
  body::-webkit-scrollbar {
    width: 12px;
  }
  body::-webkit-scrollbar-track {
    background: var(--light-navy);
  }
  body::-webkit-scrollbar-thumb {
    background-color: var(--teal-green);
    border: 3px solid var(--light-navy);
    border-radius: 10px;
  }
  body {
    margin: 0;
    width: 100%;
    min-height: 100%;
    overflow-x: hidden;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    background-color: var(--light-navy);
    /* color: var(--slate); */
    /* font-family: var(--font-comfortaa); */
    font-family:'Poppins', 'Comfortaa', cursive;
    /* font-family: 'Open Sans', sans-serif; */
    font-size: var(--fz-xl);
    line-height: 1.3;
    &.hidden {
      overflow: hidden;
    }
  }

   #team, #blogs, #contact_us,#services , #career, #blog{
    padding: 80px 0px 50px  0px;
    min-height:90vh;
    @media(max-width:1024px){
      min-height: unset;
      height: auto;
    }
  }
   #blog{
    padding: 00px 0px 50px  0px;
    min-height:90vh;
    @media(max-width:768px){
      min-height: unset;
      height: auto;
    }
  }

  .careerImage{
    max-height: 60px;
	margin-bottom: -12px;
	@media (max-width: 768px) {
		width: 12rem;
	}
	@media (max-width: 500px) {
		width: 10rem;
		margin-bottom: -10px;
	}
  }
  .logoImage{
    max-width: 18rem;
	  max-height: 64px;
	@media (max-width: 768px) {
		width: 12rem;
	}
	@media (max-width: 500px) {
		width: 12rem;
	}
  }
 
  #header >div>div{
    min-height:80vh;
  }
  #header{
    padding-bottom: 50px;
  }

  #header  h2{
    font-weight:700;
  }

  #services{
    background:var(--lightest-slate);
    padding-bottom:64px;
  }
  
  
 
  #blogs{
    background: var(--light-grey);
  }
  

  #services h3{
    margin-top:16px;
  }
  .serviceContainer{
    min-height:70vh;
  }
  #second-services{
    padding:40px 0px;
  }
  #trusted_by,
  #career{
    background:var(--lightest-slate);
  }

    #trusted_by{
      padding: 50px 0px;
    }
    .serviceCard{
      padding:32px 64px;
    }
    .media-wrapper-4{
      padding:0px;
      border-radius:unset;
      background:var(---white)!important;
      width:100%;
      @media(max-width:500px){
        overflow: hidden;
        min-height:30vh;
      }
    }
    
    .media-wrapper-4 img{
      border-radius:unset;
      background:unset;
      width:auto;
      height:70%;
      @media(max-width:768px){
        height:50%;
      }
      @media(max-width:500px){
        height:40%;
      }
    }
    .media-wrapper-4 .imageTitle{
      display: none;
    }
      
    .media-wrapper-0,
    .media-wrapper-1,
    .media-wrapper-2,
    .media-wrapper-3
    {
      display:flex;
      justify-content: center;
    }
    .footerTopGrid{
      max-width:200px;
      @media(max-width:768px){
        max-width:200px;
      }
    }
    .careerTextLogo{
      width:auto;
      max-height:60px;
      margin-bottom: -12px;
    }
    //recent Blogs
    .blogCardActions{
      display:flex;
      justify-content: space-between;
      padding:16px 0px 0px 0px;
    }
    .blogCardActions a{
      text-transform: none;
    }
    .blogCardMedia{
      border-radius: 20px;
    }
    .blogCardTitle{
      max-height:5.5rem;
      min-height:5.5rem;
      overflow: hidden;
    }
    .recentBlog{
      padding:unset!important;
      margin-bottom: 24px;
      border-radius: 6px;
      background: var(--lightest-slate)!important;
      display:flex;
      p{
        line-height:1.5rem;
        max-height:4.5rem;
        overflow:hidden;
      }
      img{
        width:140px;
        height:100%;
        border:1px solid var(--lightest-slate);
        border-radius:6px;
      }
    }
    .blogBody p>a,
    .blogBody a{
      @media(max-width:500px){
        max-width: 300px;
        display: table-caption;
        overflow-wrap: break-word;
      }
    }
    .blogBody blockquote{
      padding-left:10px;
      border-left:10px solid var(--light-slate);
    }
    
    .blogBody p{
      font-size: 1rem;
      line-height: 1.5;
      font-weight:500;
      color:var(--dark-navy);
    }
    .blogBody h2{
      font-size: 1.5rem;
      font-weight:600;
    }
    //footer
    .footerBox{
      padding: 16px 0px;
      display:flex;
      justify-content: center;
      background: var(--navy);
      font-weight: 500;
      margin-top: 0;
    }
    .footerBoxTop{
    padding: 16px 32px 16px 0px;
    display:flex;
    justify-content: center;
    background: var(--lightest-slate);
    @media(max-width:500px){
      /* margin-left:-24px; */
    /* padding: 16px 0px; */
    }
    }
    .raechUsTitle{
      margin-left: 16px;
      @media (max-width: 500px) {
        margin-top: 18px;
      }
    }
    .footerFlex{
      display: flex;
      justify-content: center;
      align-items: center;
      height:100%;
      @media (max-width: 768px) {
        justify-content: space-between;
      }
      @media (max-width: 500px) {
        justify-content: space-around;
      }
    }
    .colorLightestSlate{
      color:var(--lightest-slate);
    }

    .flex-row{
      display:flex;
    }
    .footerLogo{
      margin-left: 8px;
      @media (max-width: 768px) {
        margin-left: 8px;
      }
    }
    .footerIcon{   
      color: var(--dark-navy);
      padding: 0px 16px;
    }
    .footerIconButton{
      padding:  16px;
      color: var(--dark-navy);
      font-size: 32px;
      @media(max-width:768px){
        padding:8px;
        font-size: 20px;
      }
    }

    //contact us
    .contactForm{
      @media (min-width: 768px) {
        padding-right: 8px;
      }
      @media (max-width: 500px) {
        padding: 0px 24px;
      }
    }
    .MuiFilledInput-root {
      background-color:var(--lightest-slate);
    }
    //blog
    #blog .MuiPaper-root{
      background-color: transparent;
      @media(min-width:1024px){
        padding:0px 0px 0px 8px;
      }
    }

    //serviceTimeline

    .timelineIndex{
      width: 40px;
      height: 40px;
      background: var(--teal-green);
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      @media (max-width: 500px) {
        width: 24px;
        height: 24px;
        font-size: 14px;
      }
    }
    
    .timelineImage{
      width: 250px;
      height: 250px;
      @media (max-width: 768px) {
        width: 200px;
        height: 200px;
      }
      @media (max-width: 500px) {
        width: 150px;
        height: 150px;
      }
    }
    .timelineContent{
      padding: 6px 16px;
      text-align: left;
    }
    .timelineImageWrapper{
      display: flex;
      justify-content: center;
    }
    .timelineDot{
    background:var(--lightest-slate);
    padding:8px;
    }
    .timelineConnector{
    background:var(--light-slate);
    }
    //Navbar
    
    .active{
      color:var(--teal-green);
      border-bottom:2px solid var(--teal-green);
      @media(max-width:768px){
        border:none;
      }
    }
    .activeMenuItem{
      background:var(--light-slate);
    }
    .menuItem{
      /* background:var(--lightest-slate) */
    }
    //Hiring SVG Image CSS
    svg#freepik_stories-job-offers:not(.animated) .animable {opacity: 0;}svg#freepik_stories-job-offers.animated #freepik--Window--inject-58 {
      animation: 1s 1 forwards linear slideUp,1.5s Infinite  linear shake;
      animation-delay: 0s,1s;
    }
    @keyframes slideUp {
      0% {
        opacity: 0;
        transform: translateY(30px);
      }
       100% {
         opacity: 1;
         transform: inherit;
        }
       }
        @keyframes shake {
          10%, 90% {
            transform: translate3d(-1px, 0, 0);
          }
          20%, 80% {
            transform: translate3d(2px, 0, 0);
           }
            30%, 50%, 70% {
               transform: translate3d(-4px, 0, 0);
              }
               40%, 60% {
                  transform: translate3d(4px, 0, 0);
                 }
                 }
  `;

export default GlobalStyle;
