import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
	Timeline,
	TimelineItem,
	TimelineSeparator,
	TimelineDot,
	TimelineConnector,
	TimelineContent,
	TimelineOppositeContent
} from '@material-ui/lab';

import Typography from '@material-ui/core/Typography';
import { Container, useMediaQuery } from '@material-ui/core';
import { secondServices } from '../data';

import { cardList, cardItem } from '../utils/animationVariants';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const useStyles = makeStyles((theme) => ({
	paper: {
		padding: '6px 16px',
		textAlign: 'left'
	},
	secondaryTail: {
		backgroundColor: theme.palette.secondary.main
	},
	alignAlternate: { flexDirection: 'row!important' },
	MuiTimelineOppositeContentRoot: {
		flex: 0,
		padding: 'unset'
	}
}));

const MotionTimeline = motion(React.forwardRef((props, ref) => <Timeline ref={ref} {...props} />));
const MotionTimelineItem = motion(React.forwardRef((props, ref) => <TimelineItem ref={ref} {...props} />));

const ServicesTimeline = ({ setActive }) => {
	const classes = useStyles();
	const isMdSmall = useMediaQuery((theme) => theme.breakpoints.down('md'));
	const isXsSmall = useMediaQuery((theme) => theme.breakpoints.down('xs'));
	const { ref, inView } = useInView({ threshold: isMdSmall ? '0.1' : 0.2 });
	const cardListVisible = useAnimation();
	const cardItemVisible = useAnimation();
	useEffect(
		() => {
			if (inView) {
				cardListVisible.start('visible');
				cardItemVisible.start('visible');
				setActive('/#services');
			}
			if (!inView) {
				cardListVisible.start('hidden');
				cardItemVisible.start('hidden');
			}
		},
		[ inView ]
	);

	return (
		<Container ref={ref}>
			<Typography
				variant={isXsSmall ? 'h5' : 'h4'}
				color="secondary"
				className="main-heading"
				component="h1"
				align="center"
			>
				How we process the data
			</Typography>
			<MotionTimeline align="alternate" initial="hidden" animate={cardListVisible} variants={cardList}>
				{secondServices.map(({ title, imageURL, body }, i) => (
					<MotionTimelineItem
						classes={isXsSmall ? { alignAlternate: classes.alignAlternate } : {}}
						key={i}
						custom={i}
						initial="hidden"
						animate={cardItemVisible}
						variants={cardItem}
					>
						<TimelineOppositeContent
							classes={isXsSmall ? { root: classes.MuiTimelineOppositeContentRoot } : {}}
						>
							{!isXsSmall && (
								<div className="timelineImageWrapper">
									<img className="timelineImage" src={imageURL} />
								</div>
							)}
						</TimelineOppositeContent>
						<TimelineSeparator>
							<TimelineDot className="timelineDot">
								<span className="timelineIndex">{i + 1}</span>
							</TimelineDot>
							<TimelineConnector className="timelineConnector" />
						</TimelineSeparator>
						<TimelineContent>
							<div className="timelineContent">
								<Typography variant={isXsSmall ? 'h6' : 'h5'} component="h1" color="textSecondary">
									{title}
								</Typography>
								{isXsSmall && (
									<div className="timelineImageWrapper">
										<img className="timelineImage" src={imageURL} />
									</div>
								)}
								<Typography variant={isXsSmall ? 'caption' : 'subtitle1'} align="justify" component="p">
									{body}
								</Typography>
							</div>
						</TimelineContent>
					</MotionTimelineItem>
				))}
			</MotionTimeline>
			{/* <div style={{ display: 'flex', justifyContent: 'center' }}>
				<StyledTypography align="center" variant="body1" component="p">
					we work for any data, any data type and all industries
				</StyledTypography>
			</div> */}
		</Container>
	);
};

export default ServicesTimeline;
