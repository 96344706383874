import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardActionArea, CardActions, CardContent, CardMedia, Button, Typography } from '@material-ui/core';
import styled from 'styled-components';

const StyledCard = styled(Card)`
	margin-bottom:16px;
	max-width:380px;
	height: 100%;
	border-radius: 20px;
	background:var(--white);
	box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.25);
`;

const StyledCardMedia = styled(CardMedia)`
	background:#fff;
`;

const StyledCardActionArea = styled(CardActionArea)`
	/* border-radius: 30px; */
	/* height:100%; */
	cursor: unset;
`;
const MediaContainer = styled.div`position: relative;`;
const StyledTypography = styled(CardMedia)`
		margin-left:4px;
		position:absolute;
		bottom:-10px;
		padding:8px 16px;
		border-radius:16px;
		background:var(--light-less-slate);
	`;

const ServiceBox = ({ service }) => {
	// const classes = useStyles();
	const { title, body, imageURL } = service;
	return (
		<StyledCard>
			<StyledCardActionArea>
				<MediaContainer>
					<StyledCardMedia
						component="img"
						alt="Contemplative Reptile"
						height="140"
						image={imageURL}
						title="Contemplative Reptile"
					/>

					<StyledTypography variant="h5" component="h3">
						{title}
					</StyledTypography>
				</MediaContainer>

				<CardContent>
					<Typography variant="body2" component="p" align="justify">
						{body}
					</Typography>
				</CardContent>
			</StyledCardActionArea>
		</StyledCard>
	);
};

export default ServiceBox;
