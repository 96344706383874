// Freamer Motion

export const headerPoints = {
	enter: (i) => ({
		x: 0,
		opacity: 1,
		zIndex: 1
	}),
	center: {
		zIndex: 1,
		x: '-10vw',
		opacity: 0,
		transition: { duration: 0.6, delay: 3, type: 'spring', restSpeed: 0.5 }
	},
	exit: (i) => ({
		zIndex: 0,
		x: 100,
		opacity: 0
	})
};
export const banner = {
	initial: { y: 20 },
	animate: {
		transition: {
			delayChildren: 0.4,
			staggerChildren: 0.1
		}
	}
};

export const letterAni = {
	initial: { y: 20 },
	animate: {
		y: 0,
		transition: {
			ease: [ 0.6, 0.01, -0.05, 0.95 ],
			duration: 2
		}
	}
};
export const navList = {
	visible: {
		opacity: 1,
		transition: {
			when: 'beforeChildren',
			staggerChildren: 0.3
		}
	},
	hidden: {
		opacity: 0,
		transition: {
			when: 'afterChildren'
		}
	}
};
export const navItem = {
	visible: (i) => ({
		opacity: 1,
		y: 0,
		transition: {
			delay: i * 0.2,
			duration: 0.25
		}
	}),
	hidden: {
		opacity: 0,
		y: -20
	}
};
export const cardList = {
	visible: {
		opacity: 1,
		transition: {
			when: 'beforeChildren',
			staggerChildren: 0.3
		}
	},
	hidden: {
		opacity: 0,
		transition: {
			when: 'afterChildren'
		}
	}
};
export const cardItem = {
	visible: (i) => ({
		opacity: 1,
		y: 0,
		transition: {
			delay: i * 0.2,
			duration: 0.5
		}
	}),
	hidden: {
		opacity: 0,
		y: 10
	}
};

export const screenThreshold = 0.4;

export const cardHover = { y: -5, transition: { duration: 0.4 } };
export const cardTap = { scale: 0.95 };
export const sectionHeader = { y: 10 };
export const sectionHeaderInitial = { y: 20 };
export const sectionHeaderTransition = { duration: 0.5 };
