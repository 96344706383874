import React from 'react';

const HiringSVG = () => {
	return (
		<div>
			<svg
				className="animated"
				id="freepik_stories-job-offers"
				xmlns="http://www.w3.org/2000/svg"
				viewBox="0 0 750 500"
				version="1.1"
				xmlnsXlink="http://www.w3.org/1999/xlink"
				// xmlns:svgjs="http://svgjs.com/svgjs"
			>
				<g
					id="freepik--background-complete--inject-58"
					className="animable"
					style={{ transformOrigin: '368.315px 289.82px' }}
				>
					<rect
						x="29.04"
						y="142.11"
						width="156.2"
						height="87.34"
						style={{ fill: 'rgb(250, 250, 250)', transformOrigin: '107.14px 185.78px' }}
						id="eljnd6vl8bhtd"
						className="animable"
					/>
					<path
						d="M185.24,229.45s0-.54,0-1.57,0-2.57,0-4.56c0-4,0-9.9-.05-17.47,0-15.12-.06-36.93-.1-63.74l.2.2-156.19.06h0l.26-.26c0,30.89,0,60.43,0,87.34l-.24-.24,112.33.12,32.27.06,8.61,0,2.22,0h.77l-.74,0h-2.19l-8.56,0-32.22.07L29,229.7h-.25v-.25c0-26.91,0-56.45,0-87.34v-.26h.28l156.19.06h.2v.2c-.05,26.87-.08,48.72-.11,63.87,0,7.54,0,13.42-.05,17.41,0,2,0,3.5,0,4.53S185.24,229.45,185.24,229.45Z"
						style={{ fill: 'rgb(224, 224, 224)', transformOrigin: '107.085px 185.775px' }}
						id="el2o331iada4n"
						className="animable"
					/>
					<rect
						x="48.12"
						y="162.93"
						width="22.08"
						height="22.08"
						style={{ fill: 'rgb(224, 224, 224)', transformOrigin: '59.16px 173.97px' }}
						id="elh8ffochwspg"
						className="animable"
					/>
					<path
						d="M100.54,166c0,.14-5.16.26-11.51.26s-11.52-.12-11.52-.26,5.16-.26,11.52-.26S100.54,165.89,100.54,166Z"
						style={{ fill: 'rgb(224, 224, 224)', transformOrigin: '89.025px 166px' }}
						id="elwimjndw9k5a"
						className="animable"
					/>
					<path
						d="M174.07,174c0,.14-21.61.26-48.27.26s-48.29-.12-48.29-.26,21.62-.26,48.29-.26S174.07,173.83,174.07,174Z"
						style={{ fill: 'rgb(224, 224, 224)', transformOrigin: '125.79px 174px' }}
						id="elvo0e30d27h"
						className="animable"
					/>
					<path
						d="M141.59,182.88c0,.14-14.21.26-31.73.26s-31.72-.12-31.72-.26,14.2-.26,31.72-.26S141.59,182.73,141.59,182.88Z"
						style={{ fill: 'rgb(224, 224, 224)', transformOrigin: '109.865px 182.88px' }}
						id="el1hkwj32rwwh"
						className="animable"
					/>
					<path
						d="M162.11,217.88H55.41a10.24,10.24,0,0,1-10.23-10.24h0a10.23,10.23,0,0,1,10.23-10.23h106.7a10.23,10.23,0,0,1,10.24,10.23h0A10.24,10.24,0,0,1,162.11,217.88Z"
						style={{ fill: 'rgb(224, 224, 224)', transformOrigin: '108.765px 207.645px' }}
						id="elxmtq3o1igm"
						className="animable"
					/>
					<path
						d="M89.15,209.08H86.53l-.5,1.21H84.69l2.52-5.64H88.5L91,210.29H89.65Zm-.41-1-.9-2.16L87,208.09Z"
						style={{ fill: 'rgb(255, 255, 255)', transformOrigin: '87.845px 207.47px' }}
						id="el4riangrtajf"
						className="animable"
					/>
					<path
						d="M96.5,206.69c0,1.26-1,2-2.45,2H92.91v1.56h-1.3v-5.64h2.44C95.55,204.65,96.5,205.43,96.5,206.69Zm-1.32,0c0-.62-.41-1-1.21-1H92.91v2H94C94.77,207.67,95.18,207.31,95.18,206.69Z"
						style={{ fill: 'rgb(255, 255, 255)', transformOrigin: '94.055px 207.43px' }}
						id="elm7nxcvc11ll"
						className="animable"
					/>
					<path
						d="M102.31,206.69c0,1.26-.94,2-2.44,2H98.73v1.56H97.42v-5.64h2.45C101.37,204.65,102.31,205.43,102.31,206.69Zm-1.32,0c0-.62-.4-1-1.2-1H98.73v2h1.06C100.59,207.67,101,207.31,101,206.69Z"
						style={{ fill: 'rgb(255, 255, 255)', transformOrigin: '99.865px 207.43px' }}
						id="el12qpkrlx8s3"
						className="animable"
					/>
					<path
						d="M103.24,204.65h1.31v4.57h2.83v1.07h-4.14Z"
						style={{ fill: 'rgb(255, 255, 255)', transformOrigin: '105.31px 207.47px' }}
						id="elbarg4tr38bk"
						className="animable"
					/>
					<path
						d="M110.24,208.29v2h-1.31v-2l-2.18-3.62h1.38l1.51,2.5,1.51-2.5h1.28Z"
						style={{ fill: 'rgb(255, 255, 255)', transformOrigin: '109.59px 207.48px' }}
						id="elnmn7et49di"
						className="animable"
					/>
					<path
						d="M120.43,204.65v5.64h-1.07l-2.81-3.43v3.43h-1.29v-5.64h1.08l2.8,3.42v-3.42Z"
						style={{ fill: 'rgb(255, 255, 255)', transformOrigin: '117.845px 207.47px' }}
						id="el50114hmro5l"
						className="animable"
					/>
					<path
						d="M121.41,207.47a3.1,3.1,0,1,1,3.09,2.91A2.92,2.92,0,0,1,121.41,207.47Zm4.85,0a1.76,1.76,0,1,0-1.76,1.8A1.71,1.71,0,0,0,126.26,207.47Z"
						style={{ fill: 'rgb(255, 255, 255)', transformOrigin: '124.504px 207.283px' }}
						id="elz46r6ryb5f"
						className="animable"
					/>
					<path
						d="M136.91,204.65l-1.85,5.64h-1.4l-1.24-3.82-1.28,3.82h-1.39l-1.86-5.64h1.36l1.27,4,1.33-4h1.21l1.29,4,1.31-4Z"
						style={{ fill: 'rgb(255, 255, 255)', transformOrigin: '132.4px 207.47px' }}
						id="elk4nz4q8k9fa"
						className="animable"
					/>
					<rect
						x="53.53"
						y="328.1"
						width="135.01"
						height="75.49"
						style={{ fill: 'rgb(250, 250, 250)', transformOrigin: '121.035px 365.845px' }}
						id="elum0x75ye26i"
						className="animable"
					/>
					<path
						d="M188.54,403.6s0-.47,0-1.37,0-2.22,0-3.94c0-3.47,0-8.56-.06-15.1,0-13.07-.06-31.92-.1-55.09l.2.2-135,.06h0l.26-.26c0,26.7,0,52.23,0,75.5l-.24-.25,97.08.12,27.9.07,7.44,0h1.93l.66,0H186l-7.39,0-27.85.07-97.24.12h-.24v-.24c0-23.27,0-48.8,0-75.5v-.26h.27l135,.06h.2v.2c-.05,23.23-.08,42.12-.11,55.21,0,6.52,0,11.6,0,15.05,0,1.71,0,3,0,3.92S188.54,403.6,188.54,403.6Z"
						style={{ fill: 'rgb(224, 224, 224)', transformOrigin: '121.015px 365.73px' }}
						id="elak8v8n05m5"
						className="animable"
					/>
					<rect
						x="70.03"
						y="346.1"
						width="19.09"
						height="19.09"
						style={{ fill: 'rgb(224, 224, 224)', transformOrigin: '79.575px 355.645px' }}
						id="el0ufilj2kvdz"
						className="animable"
					/>
					<path
						d="M115.33,348.78a96.71,96.71,0,0,1-10,.26,96.55,96.55,0,0,1-9.95-.26,96.55,96.55,0,0,1,9.95-.26A96.71,96.71,0,0,1,115.33,348.78Z"
						style={{ fill: 'rgb(224, 224, 224)', transformOrigin: '105.355px 348.78px' }}
						id="elmasmu0ztmde"
						className="animable"
					/>
					<path
						d="M178.89,355.64c0,.14-18.68.26-41.73.26s-41.73-.12-41.73-.26,18.68-.26,41.73-.26S178.89,355.5,178.89,355.64Z"
						style={{ fill: 'rgb(224, 224, 224)', transformOrigin: '137.16px 355.64px' }}
						id="eliolpzhazp2"
						className="animable"
					/>
					<path
						d="M150.81,363.34c0,.14-12.28.26-27.42.26S96,363.48,96,363.34s12.28-.26,27.42-.26S150.81,363.19,150.81,363.34Z"
						style={{ fill: 'rgb(224, 224, 224)', transformOrigin: '123.405px 363.34px' }}
						id="elajnz8cb8g0l"
						className="animable"
					/>
					<path
						d="M168.55,393.59H76.33a8.85,8.85,0,0,1-8.85-8.85h0a8.84,8.84,0,0,1,8.85-8.84h92.22a8.84,8.84,0,0,1,8.85,8.84h0A8.85,8.85,0,0,1,168.55,393.59Z"
						style={{ fill: 'rgb(224, 224, 224)', transformOrigin: '122.44px 384.745px' }}
						id="ellw1d5wvols"
						className="animable"
					/>
					<path
						d="M105.48,386h-2.26l-.43,1h-1.15l2.17-4.87h1.11L107.1,387h-1.19Zm-.35-.86-.77-1.86-.78,1.86Z"
						style={{ fill: 'rgb(255, 255, 255)', transformOrigin: '104.37px 384.565px' }}
						id="el7084v07lsky"
						className="animable"
					/>
					<path
						d="M111.83,383.93c0,1.08-.81,1.76-2.11,1.76h-1V387h-1.13v-4.87h2.11C111,382.16,111.83,382.83,111.83,383.93Zm-1.14,0c0-.54-.35-.85-1-.85h-.92v1.69h.92C110.34,384.77,110.69,384.46,110.69,383.93Z"
						style={{ fill: 'rgb(255, 255, 255)', transformOrigin: '109.71px 384.565px' }}
						id="elc4io9ohjbvk"
						className="animable"
					/>
					<path
						d="M116.86,383.93c0,1.08-.81,1.76-2.11,1.76h-1V387h-1.13v-4.87h2.11C116.05,382.16,116.86,382.83,116.86,383.93Zm-1.14,0c0-.54-.35-.85-1-.85h-.91v1.69h.91C115.37,384.77,115.72,384.46,115.72,383.93Z"
						style={{ fill: 'rgb(255, 255, 255)', transformOrigin: '114.74px 384.565px' }}
						id="elkdfx33twkte"
						className="animable"
					/>
					<path
						d="M117.67,382.16h1.12v3.95h2.45V387h-3.57Z"
						style={{ fill: 'rgb(255, 255, 255)', transformOrigin: '119.455px 384.58px' }}
						id="elh2y7n1jszsc"
						className="animable"
					/>
					<path
						d="M123.71,385.3V387h-1.13v-1.74l-1.88-3.13h1.19l1.31,2.16,1.3-2.16h1.1Z"
						style={{ fill: 'rgb(255, 255, 255)', transformOrigin: '123.15px 384.565px' }}
						id="elbqa8t7l348l"
						className="animable"
					/>
					<path
						d="M132.52,382.16V387h-.93l-2.42-3v3h-1.12v-4.87H129l2.43,3v-3Z"
						style={{ fill: 'rgb(255, 255, 255)', transformOrigin: '130.285px 384.565px' }}
						id="elgz2zauxlvq"
						className="animable"
					/>
					<path
						d="M133.37,384.59a2.67,2.67,0,1,1,2.67,2.52A2.52,2.52,0,0,1,133.37,384.59Zm4.19,0a1.52,1.52,0,1,0-1.52,1.56A1.48,1.48,0,0,0,137.56,384.59Z"
						style={{ fill: 'rgb(255, 255, 255)', transformOrigin: '136.036px 384.442px' }}
						id="elkcc3mfdewzs"
						className="animable"
					/>
					<path
						d="M146.76,382.16,145.17,387H144l-1.08-3.3-1.1,3.3h-1.21l-1.6-4.87h1.17l1.1,3.42,1.15-3.42h1l1.12,3.45,1.13-3.45Z"
						style={{ fill: 'rgb(255, 255, 255)', transformOrigin: '142.885px 384.565px' }}
						id="elhuvh84flw9f"
						className="animable"
					/>
					<rect
						x="523.89"
						y="362.16"
						width="135.01"
						height="75.49"
						style={{ fill: 'rgb(250, 250, 250)', transformOrigin: '591.395px 399.905px' }}
						id="elpyljea35yzp"
						className="animable"
					/>
					<path
						d="M658.9,437.66s0-.47,0-1.37,0-2.22,0-3.94c0-3.47,0-8.56-.05-15.1,0-13.07-.06-31.92-.11-55.09l.2.2-135,.06h0l.26-.26c0,26.7,0,52.23,0,75.5l-.24-.25,97.08.12,27.9.07,7.44,0h1.93l.66,0h-2.53l-7.39,0-27.85.07-97.24.12h-.24v-.24c0-23.27,0-48.8,0-75.5v-.26h.27l135,.06h.2v.2c-.05,23.23-.08,42.12-.11,55.21,0,6.52,0,11.6,0,15.05,0,1.71,0,3,0,3.92S658.9,437.66,658.9,437.66Z"
						style={{ fill: 'rgb(224, 224, 224)', transformOrigin: '591.455px 399.79px' }}
						id="el0yjo8xko2j9q"
						className="animable"
					/>
					<rect
						x="540.39"
						y="380.16"
						width="19.09"
						height="19.09"
						style={{ fill: 'rgb(224, 224, 224)', transformOrigin: '549.935px 389.705px' }}
						id="eliqxh0d9e4xo"
						className="animable"
					/>
					<path
						d="M585.69,382.84a190.52,190.52,0,0,1-19.9,0,190.52,190.52,0,0,1,19.9,0Z"
						style={{ fill: 'rgb(224, 224, 224)', transformOrigin: '575.74px 382.84px' }}
						id="ela8m2rrkvl4i"
						className="animable"
					/>
					<path
						d="M649.25,389.7c0,.14-18.68.26-41.73.26s-41.73-.12-41.73-.26,18.68-.26,41.73-.26S649.25,389.56,649.25,389.7Z"
						style={{ fill: 'rgb(224, 224, 224)', transformOrigin: '607.52px 389.7px' }}
						id="elvydxzne5f6r"
						className="animable"
					/>
					<path
						d="M621.17,397.4c0,.14-12.28.26-27.42.26s-27.42-.12-27.42-.26,12.28-.26,27.42-.26S621.17,397.25,621.17,397.4Z"
						style={{ fill: 'rgb(224, 224, 224)', transformOrigin: '593.75px 397.4px' }}
						id="elgtvqa1bvbsm"
						className="animable"
					/>
					<path
						d="M638.91,427.65H546.69a8.85,8.85,0,0,1-8.85-8.85h0a8.84,8.84,0,0,1,8.85-8.84h92.22a8.84,8.84,0,0,1,8.85,8.84h0A8.85,8.85,0,0,1,638.91,427.65Z"
						style={{ fill: 'rgb(224, 224, 224)', transformOrigin: '592.8px 418.805px' }}
						id="elbppzh4s0gs4"
						className="animable"
					/>
					<path
						d="M575.85,420.05h-2.27l-.43,1H572l2.17-4.87h1.11l2.18,4.87h-1.18Zm-.36-.86-.77-1.87-.77,1.87Z"
						style={{ fill: 'rgb(255, 255, 255)', transformOrigin: '574.73px 418.615px' }}
						id="ely1rzx6r3orc"
						className="animable"
					/>
					<path
						d="M582.2,418c0,1.08-.82,1.76-2.12,1.76h-1v1.34H578v-4.87h2.11C581.38,416.22,582.2,416.89,582.2,418Zm-1.14,0c0-.54-.35-.85-1-.85h-.92v1.69H580C580.71,418.83,581.06,418.51,581.06,418Z"
						style={{ fill: 'rgb(255, 255, 255)', transformOrigin: '580.1px 418.665px' }}
						id="elz4919e6v0lf"
						className="animable"
					/>
					<path
						d="M587.23,418c0,1.08-.82,1.76-2.12,1.76h-1v1.34H583v-4.87h2.11C586.41,416.22,587.23,416.89,587.23,418Zm-1.15,0c0-.54-.34-.85-1-.85h-.92v1.69h.92C585.74,418.83,586.08,418.51,586.08,418Z"
						style={{ fill: 'rgb(255, 255, 255)', transformOrigin: '585.115px 418.665px' }}
						id="elti4ei6cxs8n"
						className="animable"
					/>
					<path
						d="M588,416.22h1.13v3.95h2.44v.92H588Z"
						style={{ fill: 'rgb(255, 255, 255)', transformOrigin: '589.785px 418.655px' }}
						id="el19x1jn0bla5"
						className="animable"
					/>
					<path
						d="M594.07,419.36v1.73H593v-1.74l-1.89-3.13h1.2l1.3,2.16,1.3-2.16H596Z"
						style={{ fill: 'rgb(255, 255, 255)', transformOrigin: '593.555px 418.655px' }}
						id="eltet93r1tbge"
						className="animable"
					/>
					<path
						d="M602.88,416.22v4.87H602l-2.43-3v3h-1.12v-4.87h.94l2.42,3v-3Z"
						style={{ fill: 'rgb(255, 255, 255)', transformOrigin: '600.665px 418.655px' }}
						id="elk4eicjrery"
						className="animable"
					/>
					<path
						d="M603.74,418.65a2.67,2.67,0,1,1,2.66,2.52A2.51,2.51,0,0,1,603.74,418.65Zm4.19,0a1.53,1.53,0,1,0-1.53,1.56A1.49,1.49,0,0,0,607.93,418.65Z"
						style={{ fill: 'rgb(255, 255, 255)', transformOrigin: '606.406px 418.502px' }}
						id="elfyyfv8kjare"
						className="animable"
					/>
					<path
						d="M617.12,416.22l-1.59,4.87h-1.21l-1.07-3.3-1.11,3.3h-1.2l-1.6-4.87h1.17l1.1,3.42,1.14-3.42h1l1.11,3.45,1.13-3.45Z"
						style={{ fill: 'rgb(255, 255, 255)', transformOrigin: '613.23px 418.655px' }}
						id="elzti9ichxsi"
						className="animable"
					/>
					<rect
						x="551.33"
						y="177.83"
						width="156.2"
						height="87.34"
						style={{ fill: 'rgb(250, 250, 250)', transformOrigin: '629.43px 221.5px' }}
						id="elwm60lfi67cb"
						className="animable"
					/>
					<path
						d="M707.53,265.17s0-.54,0-1.57,0-2.56,0-4.56c0-4,0-9.9,0-17.46,0-15.12-.06-36.93-.11-63.75l.2.2-156.19.06h0l.26-.26c0,30.89,0,60.43,0,87.34l-.24-.24,112.33.12,32.28.07,8.6,0h3l-.73,0h-2.2l-8.56,0-32.22.07-112.49.12h-.24v-.25c0-26.91,0-56.45,0-87.34v-.26h.27l156.19.06h.2v.2c0,26.87-.08,48.72-.11,63.87,0,7.54,0,13.42,0,17.42,0,2,0,3.49,0,4.52S707.53,265.17,707.53,265.17Z"
						style={{ fill: 'rgb(224, 224, 224)', transformOrigin: '629.55px 221.385px' }}
						id="elcm6t5gw77i"
						className="animable"
					/>
					<rect
						x="570.42"
						y="198.65"
						width="22.08"
						height="22.08"
						style={{ fill: 'rgb(224, 224, 224)', transformOrigin: '581.46px 209.69px' }}
						id="elyj0nm42wqng"
						className="animable"
					/>
					<path
						d="M622.83,201.75c0,.15-5.15.26-11.51.26s-11.51-.11-11.51-.26,5.15-.26,11.51-.26S622.83,201.61,622.83,201.75Z"
						style={{ fill: 'rgb(224, 224, 224)', transformOrigin: '611.32px 201.75px' }}
						id="elmbegsm1no8"
						className="animable"
					/>
					<path
						d="M696.37,209.69c0,.15-21.62.26-48.28.26s-48.28-.11-48.28-.26,21.61-.26,48.28-.26S696.37,209.55,696.37,209.69Z"
						style={{ fill: 'rgb(224, 224, 224)', transformOrigin: '648.09px 209.69px' }}
						id="ell4vie2s661i"
						className="animable"
					/>
					<path
						d="M663.88,218.6c0,.14-14.2.26-31.72.26s-31.73-.12-31.73-.26,14.2-.26,31.73-.26S663.88,218.45,663.88,218.6Z"
						style={{ fill: 'rgb(224, 224, 224)', transformOrigin: '632.155px 218.6px' }}
						id="elkd9zyzexgfs"
						className="animable"
					/>
					<rect
						x="567.47"
						y="233.13"
						width="127.17"
						height="20.47"
						rx="10.23"
						style={{ fill: 'rgb(224, 224, 224)', transformOrigin: '631.055px 243.365px' }}
						id="el1z2parqd4cr"
						className="animable"
					/>
					<path
						d="M611.44,244.8h-2.62l-.5,1.21H607l2.51-5.64h1.29l2.52,5.64h-1.37Zm-.41-1-.89-2.16-.9,2.16Z"
						style={{ fill: 'rgb(255, 255, 255)', transformOrigin: '610.16px 243.19px' }}
						id="elile5ox37mt"
						className="animable"
					/>
					<path
						d="M618.79,242.42c0,1.25-.94,2-2.45,2h-1.13V246H613.9v-5.64h2.44C617.85,240.37,618.79,241.15,618.79,242.42Zm-1.32,0c0-.62-.4-1-1.2-1h-1.06v2h1.06C617.07,243.39,617.47,243,617.47,242.42Z"
						style={{ fill: 'rgb(255, 255, 255)', transformOrigin: '616.345px 243.18px' }}
						id="elefyotw4uj5h"
						className="animable"
					/>
					<path
						d="M624.61,242.42c0,1.25-.94,2-2.45,2H621V246h-1.3v-5.64h2.44C623.67,240.37,624.61,241.15,624.61,242.42Zm-1.32,0c0-.62-.41-1-1.2-1H621v2h1.07C622.88,243.39,623.29,243,623.29,242.42Z"
						style={{ fill: 'rgb(255, 255, 255)', transformOrigin: '622.155px 243.18px' }}
						id="elyyjsh7v5m8a"
						className="animable"
					/>
					<path
						d="M625.54,240.37h1.3V245h2.83V246h-4.13Z"
						style={{ fill: 'rgb(255, 255, 255)', transformOrigin: '627.605px 243.185px' }}
						id="elt3wfeqnuwg"
						className="animable"
					/>
					<path
						d="M632.53,244v2h-1.3v-2L629,240.37h1.39l1.5,2.5,1.51-2.5h1.28Z"
						style={{ fill: 'rgb(255, 255, 255)', transformOrigin: '631.84px 243.185px' }}
						id="el95j3rzw0cwg"
						className="animable"
					/>
					<path
						d="M642.72,240.37V246h-1.07l-2.81-3.43V246h-1.29v-5.64h1.08l2.81,3.42v-3.42Z"
						style={{ fill: 'rgb(255, 255, 255)', transformOrigin: '640.135px 243.18px' }}
						id="elem103a292pm"
						className="animable"
					/>
					<path
						d="M643.71,243.19a3.09,3.09,0,1,1,3.08,2.92A2.91,2.91,0,0,1,643.71,243.19Zm4.85,0a1.77,1.77,0,1,0-1.77,1.8A1.73,1.73,0,0,0,648.56,243.19Z"
						style={{ fill: 'rgb(255, 255, 255)', transformOrigin: '646.795px 243.022px' }}
						id="elfl7mlg13eok"
						className="animable"
					/>
					<path
						d="M659.2,240.37,657.36,246H656l-1.25-3.82L653.43,246H652l-1.85-5.64h1.35l1.27,4,1.33-4h1.21l1.29,4,1.31-4Z"
						style={{ fill: 'rgb(255, 255, 255)', transformOrigin: '654.675px 243.18px' }}
						id="el2l4d50kunvm"
						className="animable"
					/>
				</g>
				<g
					id="freepik--Window--inject-58"
					className="animable"
					style={{ transformOrigin: '367.385px 255.03px' }}
				>
					<rect
						x={136}
						y="43.46"
						width="462.78"
						height="423.17"
						style={{ fill: 'rgb(255, 255, 255)', transformOrigin: '367.39px 255.045px' }}
						id="el1989lr6omrg"
						className="animable"
					/>
					<rect
						x={136}
						y="43.46"
						width="462.78"
						height="28.15"
						style={{ fill: 'rgb(224, 224, 224)', transformOrigin: '367.39px 57.535px' }}
						id="elpb7n9zk4slr"
						className="animable"
					/>
					<path
						d="M536.48,439.46c0,.15-77.44.26-173,.26s-173-.11-173-.26,77.43-.26,173-.26S536.48,439.32,536.48,439.46Z"
						style={{ fill: 'rgb(38, 50, 56)', transformOrigin: '363.48px 439.46px' }}
						id="elk0u741krkz"
						className="animable"
					/>
					<rect
						x="328.01"
						y="354.57"
						width="6.85"
						height="36.08"
						style={{ fill: 'rgb(224, 224, 224)', transformOrigin: '331.435px 372.61px' }}
						id="els5rd8f91uvj"
						className="animable"
					/>
					<path
						d="M334.87,390.64a5.6,5.6,0,0,1,0-.74c0-.52,0-1.21,0-2.08,0-1.84,0-4.42-.08-7.63,0-6.48-.07-15.45-.11-25.62l.26.25H328c.51-.52.14-.14.26-.25h0v11c0,3.5,0,6.83,0,9.92,0,6.15-.06,11.36-.08,15.18l-.15-.14,5,.08,1.34,0,.49,0a2.17,2.17,0,0,1-.43,0l-1.32,0-5.11.07h-.13v-.15c0-3.82-.05-9-.08-15.18,0-3.09,0-6.42,0-9.92v-5.38c0-.91,0-1.84,0-2.77v-2.8h0l.27-.27h7.1v.26c0,10.24-.08,19.27-.11,25.8,0,3.18-.06,5.75-.07,7.58,0,.84,0,1.51,0,2A5.37,5.37,0,0,1,334.87,390.64Z"
						style={{ fill: 'rgb(38, 50, 56)', transformOrigin: '331.475px 372.445px' }}
						id="el1nj9vv9d4yc"
						className="animable"
					/>
					<path
						d="M347.3,349.1h-32a8.6,8.6,0,0,1-8.6-8.59H355.9A8.6,8.6,0,0,1,347.3,349.1Z"
						style={{ fill: 'rgb(38, 50, 56)', transformOrigin: '331.3px 344.805px' }}
						id="elc30welpv2vl"
						className="animable"
					/>
					<path
						d="M375.55,342.19H319.71V330.25h55.84a6,6,0,0,1,6,6h0A6,6,0,0,1,375.55,342.19Z"
						style={{ fill: '#048477', transformOrigin: '350.63px 336.22px' }}
						id="eliyj8g6a2scb"
						className="animable"
					/>
					<path
						d="M276.31,244.47A5.94,5.94,0,0,0,271.2,251l8.06,73.26a20.19,20.19,0,0,0,20.06,17.93h21.3a6,6,0,0,0,6-6h0a6,6,0,0,0-6-6H302.53a10.26,10.26,0,0,1-10.17-8.9L283,249.58a5.94,5.94,0,0,0-6.68-5.11Z"
						style={{ fill: '#048477', transformOrigin: '298.893px 293.304px' }}
						id="eldpf6zkvca8k"
						className="animable"
					/>
					<path
						d="M344.17,330.83l-60.71.85-5.93-87.27,50.81-.32a9.23,9.23,0,0,1,9.18,9.27Z"
						style={{ fill: '#048477', transformOrigin: '310.85px 287.885px' }}
						id="el6j4quu3z4wb"
						className="animable"
					/>
					<g id="eloij472wuotg">
						<g
							style={{ opacity: '0.3', transformOrigin: '298.893px 293.304px' }}
							className="animable"
							id="el5ak846joyt"
						>
							<path
								d="M276.31,244.47A5.94,5.94,0,0,0,271.2,251l8.06,73.26a20.19,20.19,0,0,0,20.06,17.93h21.3a6,6,0,0,0,6-6h0a6,6,0,0,0-6-6H301.23c-5.14,0-8.75-3.64-9.43-8.74L283,249.58a5.94,5.94,0,0,0-6.68-5.11Z"
								id="el8o1m9tuwf5n"
								className="animable"
								style={{ transformOrigin: '298.893px 293.304px' }}
							/>
						</g>
					</g>
					<rect
						x="324.8"
						y="349.1"
						width="13.27"
						height="6.9"
						style={{ fill: 'rgb(38, 50, 56)', transformOrigin: '331.435px 352.55px' }}
						id="elec2gqsecscc"
						className="animable"
					/>
					<rect
						x="324.8"
						y="388.62"
						width="13.09"
						height="31.61"
						rx="3.97"
						style={{ fill: 'rgb(224, 224, 224)', transformOrigin: '331.345px 404.425px' }}
						id="eltikuwrgpgx"
						className="animable"
					/>
					<path
						d="M333.93,420.23s.29,0,.82-.14a4,4,0,0,0,2.83-2.64,6.11,6.11,0,0,0,.17-2c0-1.49,0-3.18,0-5,0-3.76,0-8.2-.06-13.13,0-1.23,0-2.5,0-3.79a6.45,6.45,0,0,0-.14-1.88,3.63,3.63,0,0,0-.92-1.61,3.73,3.73,0,0,0-1.61-1,6.7,6.7,0,0,0-2-.15h-4.22a3.71,3.71,0,0,0-3.24,1.91,3.85,3.85,0,0,0-.47,1.85v2c0,2.68,0,5.28,0,7.75,0,4.93,0,9.37-.05,13.12a4.67,4.67,0,0,0,.43,2.51,3.85,3.85,0,0,0,3.52,2l1.74,0,2.41.05a4.22,4.22,0,0,1,.84.05,5.5,5.5,0,0,1-.84,0l-2.41.05-1.74,0a4.12,4.12,0,0,1-3.81-2.13,4.25,4.25,0,0,1-.44-1.27,8.26,8.26,0,0,1-.07-1.4c0-3.75,0-8.19-.06-13.12,0-2.47,0-5.07,0-7.75v-2a4.22,4.22,0,0,1,4.22-4.28H333a7.05,7.05,0,0,1,2.13.17,4.37,4.37,0,0,1,1.83,1.11,4.27,4.27,0,0,1,1,1.82,7.42,7.42,0,0,1,.15,2c0,1.29,0,2.56,0,3.79,0,4.93,0,9.37-.05,13.13,0,1.86,0,3.55,0,5a6.37,6.37,0,0,1-.22,2.08,4.22,4.22,0,0,1-.93,1.52,4,4,0,0,1-2.12,1.15A2.82,2.82,0,0,1,333.93,420.23Z"
						style={{ fill: 'rgb(38, 50, 56)', transformOrigin: '331.367px 404.221px' }}
						id="elwq7hhq64c4s"
						className="animable"
					/>
					<path
						d="M369.55,418.27h-77.9a7.43,7.43,0,0,0-5.61,2.26c-2.89,3-2.71,8-2.7,8.18l3.55-.15c0-1,.19-4,1.72-5.58a4,4,0,0,1,3-1.16h20.44v9.05h3.55v-9.05h53.91a4.15,4.15,0,0,1,4.14,4.14v2.67h3.55V426A7.7,7.7,0,0,0,369.55,418.27Z"
						style={{ fill: 'rgb(224, 224, 224)', transformOrigin: '330.267px 424.568px' }}
						id="elpux79m2unf"
						className="animable"
					/>
					<path
						d="M369.55,418.27a7.07,7.07,0,0,1,1,0,7.62,7.62,0,0,1,2.72.83,8,8,0,0,1,3.24,3.28,7.66,7.66,0,0,1,.84,2.85c.07,1.07,0,2.17.05,3.36v.13h-3.81v-.14c-.1-1.73.4-3.85-1.05-5.33a4,4,0,0,0-2.63-1.29c-1,0-2.16,0-3.28,0l-14.81,0-36.14,0,.24-.25c0,2.85,0,5.93,0,9.05v.26h-4.06v-9.31l.26.26H291.31a3.87,3.87,0,0,0-2,.63,4.08,4.08,0,0,0-1.32,1.63,9.81,9.81,0,0,0-.85,4.2v.26h-.24l-3.55.15h-.26v-.26a14.43,14.43,0,0,1,.77-4.94,8.93,8.93,0,0,1,2.73-4.09,7.77,7.77,0,0,1,4.54-1.65c1.6,0,3.14,0,4.69,0l17.66,0,29.47.06,19.54.07,5.25.05,1.36,0,.46,0s-.15,0-.46,0l-1.36,0-5.25,0-19.54.08-29.47.06-17.66,0c-1.54,0-3.11,0-4.66,0a7.17,7.17,0,0,0-4.25,1.56,8.39,8.39,0,0,0-2.58,3.86,13.84,13.84,0,0,0-.72,4.75l-.27-.24,3.54-.15-.24.26a10.25,10.25,0,0,1,.9-4.45,4.62,4.62,0,0,1,1.49-1.83,4.35,4.35,0,0,1,2.26-.72h21.07v.26c0,3.11,0,6.19,0,9.05l-.25-.25h3.54l-.25.25c0-3.12,0-6.2,0-9.05v-.25h.25l36.14,0,14.81,0c1.13,0,2.21,0,3.31,0a4.32,4.32,0,0,1,3.94,4.2c0,.95,0,1.87,0,2.76l-.14-.14,3.55,0-.13.12c0-1.18.05-2.31,0-3.34a7.33,7.33,0,0,0-.79-2.78,7.79,7.79,0,0,0-3.12-3.25,7.67,7.67,0,0,0-2.67-.89C369.88,418.29,369.55,418.29,369.55,418.27Z"
						style={{ fill: 'rgb(38, 50, 56)', transformOrigin: '330.244px 424.46px' }}
						id="eld0gwktftxe"
						className="animable"
					/>
					<path
						d="M320.7,435.09a6.95,6.95,0,1,1-6.95-6.94A6.95,6.95,0,0,1,320.7,435.09Z"
						style={{ fill: 'rgb(38, 50, 56)', transformOrigin: '313.75px 435.1px' }}
						id="eluvrlxtmcmzd"
						className="animable"
					/>
					<path
						d="M292.06,433.4a6.95,6.95,0,1,1-6.95-6.95A6.95,6.95,0,0,1,292.06,433.4Z"
						style={{ fill: 'rgb(38, 50, 56)', transformOrigin: '285.11px 433.4px' }}
						id="elmzocv7fksg"
						className="animable"
					/>
					<path
						d="M381.61,433.65a6.95,6.95,0,1,1-6.95-6.94A6.95,6.95,0,0,1,381.61,433.65Z"
						style={{ fill: 'rgb(38, 50, 56)', transformOrigin: '374.66px 433.66px' }}
						id="elci6svn6vrz"
						className="animable"
					/>
					<path
						d="M377.05,330.25c0,.14-17.12.32-38.23.4s-38.23,0-38.23-.12,17.11-.32,38.23-.4S377.05,330.1,377.05,330.25Z"
						style={{ fill: 'rgb(38, 50, 56)', transformOrigin: '338.82px 330.387px' }}
						id="eltc9i062fxha"
						className="animable"
					/>
					<path
						d="M291.88,320.32c-.14,0-2.25-15.8-4.7-35.33s-4.33-35.39-4.19-35.41,2.25,15.8,4.7,35.34S292,320.3,291.88,320.32Z"
						style={{ fill: 'rgb(38, 50, 56)', transformOrigin: '287.434px 284.95px' }}
						id="elchi60lifo95"
						className="animable"
					/>
					<path
						d="M282.35,244.3a2.46,2.46,0,0,1,.54-.14,11.25,11.25,0,0,1,1.58-.16,15.62,15.62,0,0,1,5.77.89,8.22,8.22,0,0,1,3.36,2.28,20.18,20.18,0,0,1,2.57,3.87c1.5,2.89,3.17,6,5,9.29,3.67,6.51,7.12,12.35,9.46,16.67,1.19,2.15,2.1,3.91,2.72,5.14.28.57.5,1,.69,1.42a1.82,1.82,0,0,1,.2.52,3.42,3.42,0,0,1-.3-.47l-.77-1.38-2.86-5.05c-2.42-4.27-5.92-10.07-9.6-16.6-1.84-3.26-3.5-6.43-5-9.31a19.12,19.12,0,0,0-2.48-3.81,7.82,7.82,0,0,0-3.17-2.23,16.26,16.26,0,0,0-5.63-1C283.11,244.19,282.36,244.35,282.35,244.3Z"
						style={{ fill: 'rgb(38, 50, 56)', transformOrigin: '298.295px 264.035px' }}
						id="elqpicwp1nmnc"
						className="animable"
					/>
					<path
						d="M271.61,248.1c.13.07-4.47,8.09-10.26,17.9s-10.59,17.72-10.72,17.65,4.47-8.09,10.27-17.91S271.49,248,271.61,248.1Z"
						style={{ fill: 'rgb(38, 50, 56)', transformOrigin: '261.12px 265.875px' }}
						id="el6d9eo9zhqt9"
						className="animable"
					/>
					<rect
						x="242.62"
						y="283.64"
						width="83.23"
						height="24.54"
						style={{ fill: 'rgb(250, 250, 250)', transformOrigin: '284.235px 295.91px' }}
						id="eljqczz6z8sk"
						className="animable"
					/>
					<path
						d="M265.07,291.26,261,300.68h-2.15l-4.06-9.42h2.35L260,298l2.9-6.73Z"
						style={{ fill: 'rgb(38, 50, 56)', transformOrigin: '259.93px 295.97px' }}
						id="elv756ffmpwsp"
						className="animable"
					/>
					<path
						d="M271.73,298.66h-4.37l-.83,2H264.3l4.19-9.42h2.15l4.21,9.42h-2.29ZM271,297l-1.49-3.61L268.06,297Z"
						style={{ fill: 'rgb(38, 50, 56)', transformOrigin: '269.575px 295.95px' }}
						id="elbppimj6xjad"
						className="animable"
					/>
					<path
						d="M275.1,296a5.18,5.18,0,0,1,9-3.2l-1.4,1.29a3,3,0,0,0-2.36-1.1,3,3,0,1,0,0,6,3,3,0,0,0,2.36-1.11l1.4,1.29a5.17,5.17,0,0,1-9-3.19Z"
						style={{ fill: 'rgb(38, 50, 56)', transformOrigin: '279.6px 295.992px' }}
						id="el898aes6gcph"
						className="animable"
					/>
					<path
						d="M291.55,298.66h-4.37l-.83,2h-2.23l4.19-9.42h2.15l4.21,9.42h-2.29Zm-.68-1.65-1.5-3.61L287.88,297Z"
						style={{ fill: 'rgb(38, 50, 56)', transformOrigin: '289.395px 295.95px' }}
						id="elx2mhgzp6gtc"
						className="animable"
					/>
					<path
						d="M304.28,291.26v9.42h-1.79L297.8,295v5.72h-2.15v-9.42h1.8l4.68,5.72v-5.72Z"
						style={{ fill: 'rgb(38, 50, 56)', transformOrigin: '299.965px 295.99px' }}
						id="elycwc1e64px"
						className="animable"
					/>
					<path
						d="M308.45,293h-3v-1.78h8.2V293h-3v7.64h-2.18Z"
						style={{ fill: 'rgb(38, 50, 56)', transformOrigin: '309.55px 295.93px' }}
						id="elhtkaguqsrj7"
						className="animable"
					/>
					<path
						d="M323.16,306s0-.12,0-.36,0-.58,0-1c0-.91,0-2.23,0-3.93,0-3.44,0-8.43-.08-14.73l.15.15-78.5.11c.11-.12-.27.26.25-.26h0v20l-.26-.25,56,.11,16.51.07,4.46,0h1.16l.4,0a2.37,2.37,0,0,1-.37,0h-1.13l-4.4,0-16.45.07-56.16.12h-.25V306c0-3.23,0-6.53,0-9.87V285.92h0c.52-.52.15-.15.27-.26l78.5.1h.16v.16c0,6.34-.07,11.36-.09,14.82,0,1.69,0,3,0,3.9v1A1.77,1.77,0,0,1,323.16,306Z"
						style={{ fill: 'rgb(38, 50, 56)', transformOrigin: '283.955px 295.867px' }}
						id="el3atz0v69s3q"
						className="animable"
					/>
					<path
						d="M331.3,161.88h6.33L331.36,167h-4.53Z"
						style={{ fill: 'rgb(38, 50, 56)', transformOrigin: '332.23px 164.44px' }}
						id="elso7ry26onxf"
						className="animable"
					/>
					<path
						d="M260.23,210.16v13.17h-8.06V211.48c0-3.32-1.45-4.72-3.78-4.72-2.59,0-4.59,1.62-4.59,5.44v11.13h-8.07V191.82h8.07v10.61a10,10,0,0,1,6.92-2.5C256.11,199.93,260.23,203,260.23,210.16Z"
						style={{ fill: 'rgb(38, 50, 56)', transformOrigin: '247.98px 207.575px' }}
						id="el0gcy36535u9g"
						className="animable"
					/>
					<path
						d="M264.4,193.47c0-2.42,2-4.29,4.92-4.29s4.93,1.74,4.93,4.16-1.95,4.42-4.93,4.42S264.4,195.89,264.4,193.47Zm.89,6.84h8.07v23h-8.07Z"
						style={{ fill: 'rgb(38, 50, 56)', transformOrigin: '269.325px 206.245px' }}
						id="elomc72fouolm"
						className="animable"
					/>
					<path
						d="M294,199.93v7.26a13.78,13.78,0,0,0-1.83-.13c-3.27,0-5.52,1.66-5.52,5.69v10.58h-8.07v-23h7.69v2.76C287.93,201,290.6,199.93,294,199.93Z"
						style={{ fill: 'rgb(38, 50, 56)', transformOrigin: '286.29px 211.63px' }}
						id="elrgc4kem3ha"
						className="animable"
					/>
					<path
						d="M296.42,193.47c0-2.42,1.95-4.29,4.93-4.29s4.92,1.74,4.92,4.16-1.95,4.42-4.92,4.42S296.42,195.89,296.42,193.47Zm.89,6.84h8.07v23h-8.07Z"
						style={{ fill: 'rgb(38, 50, 56)', transformOrigin: '301.345px 206.245px' }}
						id="elpmkqxclpnkk"
						className="animable"
					/>
					<path
						d="M335.11,210.16v13.17H327V211.48c0-3.32-1.44-4.72-3.78-4.72-2.59,0-4.59,1.62-4.59,5.44v11.13h-8.06v-23h7.68v2.5a9.9,9.9,0,0,1,7.31-2.88C331,199.93,335.11,203,335.11,210.16Z"
						style={{ fill: 'rgb(38, 50, 56)', transformOrigin: '322.84px 211.637px' }}
						id="elzpa91l8w4z"
						className="animable"
					/>
					<path
						d="M365.26,200.31v18.81c0,8.71-4.92,12.83-13.5,12.83-4.42,0-8.62-1-11.43-3l2.93-5.65a13.37,13.37,0,0,0,7.78,2.47c4.33,0,6.15-2,6.15-5.57v-.76a8.6,8.6,0,0,1-6.83,2.72c-6.12,0-11.3-4.34-11.3-11.13s5.18-11.08,11.3-11.08c3.18,0,5.64,1,7.22,3.18v-2.8Zm-8,10.7c0-2.8-2.13-4.67-5-4.67s-5.06,1.87-5.06,4.67,2.17,4.71,5.06,4.71A4.68,4.68,0,0,0,357.28,211Z"
						style={{ fill: 'rgb(38, 50, 56)', transformOrigin: '352.16px 215.95px' }}
						id="elkn3fkmty04"
						className="animable"
					/>
					<path
						d="M369,218.91a4.84,4.84,0,0,1,9.68,0,4.84,4.84,0,0,1-9.68,0Z"
						style={{ fill: '#048477', transformOrigin: '373.84px 218.91px' }}
						id="elya0pxadhjgs"
						className="animable"
					/>
					<path
						d="M345.7,168.81v5.71a10.76,10.76,0,0,0-1.43-.1c-2.57,0-4.33,1.3-4.33,4.46v8.3H333.6V169.11h6v2.17C340.94,169.65,343,168.81,345.7,168.81Z"
						style={{ fill: 'rgb(38, 50, 56)', transformOrigin: '339.65px 177.995px' }}
						id="elzt7xwm8ecgd"
						className="animable"
					/>
					<path
						d="M366.51,179.75H353.37c.54,1.83,2.1,2.9,4.44,2.9a5.77,5.77,0,0,0,4.16-1.57l3.34,3.47c-1.74,1.93-4.27,2.94-7.7,2.94-6.44,0-10.61-4-10.61-9.37s4.24-9.31,9.94-9.31c5.33,0,9.67,3.41,9.67,9.37C366.61,178.65,366.54,179.25,366.51,179.75Zm-13.24-3.33h7.4a3.76,3.76,0,0,0-7.4,0Z"
						style={{ fill: 'rgb(38, 50, 56)', transformOrigin: '356.805px 178.15px' }}
						id="elq5nanw6yjbk"
						className="animable"
					/>
					<path
						d="M306.3,169.11l-6.44,18.07h-6.13l-3.4-9.83-3.54,9.83h-6.13l-6.44-18.07h6l3.7,10.94,3.84-10.94h5.4l3.73,11,3.84-11Z"
						style={{ fill: 'rgb(38, 50, 56)', transformOrigin: '290.26px 178.145px' }}
						id="el2v5x27mrkor"
						className="animable"
					/>
					<path
						d="M326.1,179.75H313c.54,1.83,2.1,2.9,4.44,2.9a5.79,5.79,0,0,0,4.17-1.57l3.33,3.47c-1.73,1.93-4.27,2.94-7.7,2.94-6.44,0-10.6-4-10.6-9.37s4.23-9.31,9.93-9.31c5.34,0,9.67,3.41,9.67,9.37C326.2,178.65,326.13,179.25,326.1,179.75Zm-13.24-3.33h7.41a3.76,3.76,0,0,0-7.41,0Z"
						style={{ fill: 'rgb(38, 50, 56)', transformOrigin: '316.44px 178.15px' }}
						id="eleepq6ceu6ho"
						className="animable"
					/>
					<path
						d="M278.91,135.66l-1-21.25a3.44,3.44,0,0,0-3.18-3.27h0a3.44,3.44,0,0,0-3.68,3l-2.69,21.3a2.7,2.7,0,0,0,2.68,3h5.18A2.71,2.71,0,0,0,278.91,135.66Z"
						style={{ fill: 'rgb(224, 224, 224)', transformOrigin: '273.627px 124.785px' }}
						id="elk3mucrpw5gr"
						className="animable"
					/>
					<path
						d="M269.28,99.11a5.18,5.18,0,1,1,3.18,6.6A5.18,5.18,0,0,1,269.28,99.11Z"
						style={{ fill: 'rgb(224, 224, 224)', transformOrigin: '274.17px 100.82px' }}
						id="elu54l0dnjagq"
						className="animable"
					/>
					<path
						d="M223.29,162.51,210.91,152a2.64,2.64,0,0,0-3.48.08h0a2.62,2.62,0,0,0-.23,3.61l10.48,12.58a2.07,2.07,0,0,0,3.09.09l2.69-2.89A2.05,2.05,0,0,0,223.29,162.51Z"
						style={{ fill: 'rgb(245, 245, 245)', transformOrigin: '215.299px 160.197px' }}
						id="el12fjg8lqt0me"
						className="animable"
					/>
					<path
						d="M197.89,148.88a4,4,0,1,1,5.34,1.66A4,4,0,0,1,197.89,148.88Z"
						style={{ fill: 'rgb(245, 245, 245)', transformOrigin: '201.409px 146.978px' }}
						id="eldu4lo1oajy9"
						className="animable"
					/>
					<path
						d="M212.79,113.09l19,32.24a6.06,6.06,0,0,0,7.64,2.48h0a6.07,6.07,0,0,0,3.26-7.7l-13.31-35.36a4.76,4.76,0,0,0-6.66-2.53l-8.07,4.24A4.76,4.76,0,0,0,212.79,113.09Z"
						style={{ fill: 'rgb(224, 224, 224)', transformOrigin: '227.601px 124.995px' }}
						id="elkxna7r13uyr"
						className="animable"
					/>
					<path
						d="M257.81,162.06a9.12,9.12,0,1,1-10.37-7.66A9.12,9.12,0,0,1,257.81,162.06Z"
						style={{ fill: 'rgb(224, 224, 224)', transformOrigin: '248.792px 163.419px' }}
						id="el65emt48r6ab"
						className="animable"
					/>
					<path
						d="M598.77,466.63c0-1.88-.09-168.69-.22-423.17l.22.22L136,43.72h0l.26-.26c0,154.16,0,299.12,0,423.17l-.22-.23,462.77.23L136,466.86h-.23v-.23c0-124,0-269,0-423.17V43.2h.3l462.73,0H599v.22C598.86,297.94,598.77,464.75,598.77,466.63Z"
						style={{ fill: 'rgb(38, 50, 56)', transformOrigin: '367.385px 255.03px' }}
						id="eljt16i0wece"
						className="animable"
					/>
					<circle
						cx="148.86"
						cy="57.34"
						r="3.83"
						style={{ fill: 'rgb(255, 255, 255)', transformOrigin: '148.86px 57.34px' }}
						id="elrk7kmccprf"
						className="animable"
					/>
					<circle
						cx="162.02"
						cy="57.34"
						r="3.83"
						style={{ fill: 'rgb(255, 255, 255)', transformOrigin: '162.02px 57.34px' }}
						id="el5gwr407npgq"
						className="animable"
					/>
					<circle
						cx="175.18"
						cy="57.34"
						r="3.83"
						style={{ fill: 'rgb(255, 255, 255)', transformOrigin: '175.18px 57.34px' }}
						id="elwerzbb1k4fd"
						className="animable"
					/>
				</g>
				<g
					id="freepik--Character--inject-58"
					className="animable"
					style={{ transformOrigin: '432.908px 275.842px' }}
				>
					<path
						d="M421.56,137a42.8,42.8,0,0,1-9.34-3.55,21.58,21.58,0,0,1,4.78,1.5A21.34,21.34,0,0,1,421.56,137Z"
						style={{ fill: 'rgb(69, 90, 100)', transformOrigin: '416.89px 135.225px' }}
						id="elswykt7yawjh"
						className="animable"
					/>
					<path
						d="M408.77,157.14c-1-.11-3.7,1.23-5.95,3.9l-.11.12c-1.59,1.79-2.58-3.71-2.68-5.74a2.11,2.11,0,0,0-2.74-1.83l-7,2.68-2-.07a2,2,0,0,0-1.45,2.3l2.48,10.89-.13,2.92,13.4,2.78.72-7.28c.26-.83.6-1.81,1-2.92a9.49,9.49,0,0,1,1.69-2.74,17.48,17.48,0,0,1,2.62-2.5l.23-.13a1.3,1.3,0,0,0,.41-.31A1.18,1.18,0,0,0,408.77,157.14Z"
						style={{ fill: 'rgb(255, 190, 157)', transformOrigin: '398.252px 164.291px' }}
						id="el9pxwlu5nw3"
						className="animable"
					/>
					<path
						d="M405.83,126.44s-8-1-20-10.56-18.53-23.16-19.87-23-7.5-2-16.41,23.46-4,30.68-1.74,30.43,17-5.91,26.54-5.72,22.18,4,22.18,4Z"
						style={{ fill: '#048477', transformOrigin: '375.121px 119.829px' }}
						id="elxkrsdjffjd"
						className="animable"
					/>
					<path
						d="M423.73,138.16l-2.2,5.33-1,2.39a6.66,6.66,0,0,1-8.37,3.75l-17.35-4.27,7.68-23.25,17.62,7.36A6.67,6.67,0,0,1,423.73,138.16Z"
						style={{ fill: 'rgb(38, 50, 56)', transformOrigin: '409.519px 136.059px' }}
						id="elz00b1zmheym"
						className="animable"
					/>
					<path
						d="M416.89,146l-5.81,10.3-2.65-.85L390.3,169l-4.51-9.8s10.55-4,12.63-7.65-.11-4.54-.11-4.54l5.1-5.35Z"
						style={{ fill: 'rgb(38, 50, 56)', transformOrigin: '401.34px 155.33px' }}
						id="el32xby2nkntx"
						className="animable"
					/>
					<path
						d="M350.47,116.73c-4.72,13.77-6.1,25.77-3.07,26.81s9.3-9.29,14-23.06,6.1-25.77,3.07-26.81S355.19,103,350.47,116.73Z"
						style={{ fill: 'rgb(38, 50, 56)', transformOrigin: '355.933px 118.605px' }}
						id="elg9cl59cahn"
						className="animable"
					/>
					<polygon
						points="425.86 145.37 421.17 144.37 423.49 138.74 427.1 140.54 425.86 145.37"
						style={{ fill: 'rgb(69, 90, 100)', transformOrigin: '424.135px 142.055px' }}
						id="ell83fhk3v8ya"
						className="animable"
					/>
					<path
						d="M420.47,140.27c-.06.16-2.41-.51-5.26-1.49a25.5,25.5,0,0,1-5.07-2.05,26.59,26.59,0,0,1,5.26,1.49A26.16,26.16,0,0,1,420.47,140.27Z"
						style={{ fill: 'rgb(69, 90, 100)', transformOrigin: '415.305px 138.512px' }}
						id="eljykemq1jcs"
						className="animable"
					/>
					<path
						d="M419,143.79c0,.08-.56,0-1.45-.18l-1.56-.36c-.58-.15-1.19-.39-1.84-.61a19.21,19.21,0,0,1-4.56-2c.06-.16,2.18.55,4.76,1.46.64.23,1.24.48,1.81.66s1.07.32,1.49.47C418.51,143.49,419,143.71,419,143.79Z"
						style={{ fill: 'rgb(69, 90, 100)', transformOrigin: '414.295px 142.219px' }}
						id="elshsuen17l1n"
						className="animable"
					/>
					<path
						d="M362.35,112.64s-7.21-3.58-10.07,6S356.73,129,356.73,129A61.09,61.09,0,0,0,362.35,112.64Z"
						style={{ fill: 'rgb(250, 250, 250)', transformOrigin: '356.983px 120.493px' }}
						id="el5roogs0j92i"
						className="animable"
					/>
					<path
						d="M368.56,101a10.85,10.85,0,0,1-.1,1.79c-.11,1.14-.26,2.8-.51,4.85-.19,1-.41,2.12-.63,3.31-.12.59-.24,1.21-.36,1.84-.07.32-.13.64-.19,1s-.18.65-.27,1l-1.16,4.11c-.1.36-.21.72-.31,1.08s-.26.71-.38,1.08l-.82,2.18c-.27.73-.55,1.46-.82,2.18-.14.36-.25.72-.41,1.06l-.48,1-1.83,3.87c-.14.3-.28.61-.44.9s-.32.58-.49.86l-.94,1.63c-.61,1-1.17,2-1.69,2.91-1.16,1.7-2.13,3.05-2.8,4a10.92,10.92,0,0,1-1.09,1.41,10,10,0,0,1,.9-1.54l2.64-4.09,1.62-2.93.91-1.63.47-.85c.16-.29.29-.6.43-.91.57-1.23,1.17-2.52,1.78-3.85l.47-1c.16-.34.27-.71.41-1.06.27-.71.54-1.44.81-2.17l.82-2.16c.12-.36.28-.71.38-1.07s.21-.72.31-1.07l1.19-4.08c.09-.32.2-.64.27-1s.14-.64.2-1c.14-.62.26-1.23.39-1.82.25-1.18.49-2.27.7-3.28.29-2,.53-3.64.69-4.81A11.72,11.72,0,0,1,368.56,101Z"
						style={{ fill: 'rgb(38, 50, 56)', transformOrigin: '360.702px 122.03px' }}
						id="elem0gla5sng"
						className="animable"
					/>
					<path
						d="M395.07,151.38a1.55,1.55,0,0,0-.94,1.88c.41,1.51,1.09,3.87,1.62,5.32a4,4,0,0,0,.69,1.3,1.3,1.3,0,0,0,1.29.45,1.44,1.44,0,0,0,.87-1.19,4.15,4.15,0,0,0-.18-1.56c-.34-1.4-1-3.51-1.5-5.23a1.44,1.44,0,0,0-1.85-1Z"
						style={{ fill: 'rgb(255, 190, 157)', transformOrigin: '396.34px 155.818px' }}
						id="elmy3lipazdiq"
						className="animable"
					/>
					<path
						d="M392.83,154a1.55,1.55,0,0,0-.95,1.88c.42,1.51,1.1,3.87,1.63,5.31a3.9,3.9,0,0,0,.68,1.31,1.3,1.3,0,0,0,1.29.45,1.4,1.4,0,0,0,.87-1.19,4,4,0,0,0-.17-1.56c-.35-1.4-1-3.52-1.5-5.24a1.44,1.44,0,0,0-1.85-1Z"
						style={{ fill: 'rgb(255, 190, 157)', transformOrigin: '394.093px 158.433px' }}
						id="el83jgju5luap"
						className="animable"
					/>
					<path
						d="M390.73,155.53a1.32,1.32,0,0,0-.85,1.63c.38,1.3,1,3.36,1.48,4.61a3.5,3.5,0,0,0,.62,1.12,1.21,1.21,0,0,0,1.17.4,1.23,1.23,0,0,0,.79-1,3.68,3.68,0,0,0-.16-1.35c-.32-1.22-.89-3-1.38-4.54a1.33,1.33,0,0,0-1.67-.84Z"
						style={{ fill: 'rgb(255, 190, 157)', transformOrigin: '391.887px 159.406px' }}
						id="el85z1fncex4l"
						className="animable"
					/>
					<path
						d="M388.08,155.93a1.33,1.33,0,0,0-.86,1.63c.39,1.31,1,3.38,1.5,4.63a3.24,3.24,0,0,0,.62,1.14,1.1,1.1,0,0,0,2-.64,3.47,3.47,0,0,0-.17-1.35c-.32-1.23-.89-3.07-1.38-4.57a1.32,1.32,0,0,0-1.68-.84Z"
						style={{ fill: 'rgb(255, 190, 157)', transformOrigin: '389.259px 159.829px' }}
						id="el3que4idj2m5"
						className="animable"
					/>
					<path
						d="M396.29,159.93a8.33,8.33,0,0,1-1.19-2.68,7.88,7.88,0,0,1-.67-2.85,8,8,0,0,1,1.19,2.68A7.64,7.64,0,0,1,396.29,159.93Z"
						style={{ fill: 'rgb(235, 153, 110)', transformOrigin: '395.36px 157.165px' }}
						id="el6aciv9mgkue"
						className="animable"
					/>
					<path
						d="M393.89,162a10.24,10.24,0,0,1-1.26-3.08,10.11,10.11,0,0,1-.74-3.24,10.24,10.24,0,0,1,1.26,3.08A9.85,9.85,0,0,1,393.89,162Z"
						style={{ fill: 'rgb(235, 153, 110)', transformOrigin: '392.89px 158.84px' }}
						id="elrtk1ztd2sk"
						className="animable"
					/>
					<path
						d="M391.53,162.16a6.91,6.91,0,0,1-1.3-2.67,6.58,6.58,0,0,1-.57-2.91,10.48,10.48,0,0,1,1.09,2.74A9.76,9.76,0,0,1,391.53,162.16Z"
						style={{ fill: 'rgb(235, 153, 110)', transformOrigin: '390.593px 159.37px' }}
						id="elh0xugqib8mw"
						className="animable"
					/>
					<path
						d="M474.91,125.79c-3.81-6.44-10.69-10.62-17.91-12.61-3-.83-5.1-.6-8.23-.25a13.28,13.28,0,0,0-8.26,3.83,22.29,22.29,0,0,0-5.86,9.42,62.32,62.32,0,0,0-2.28,11.28,21,21,0,0,0,1.92,11.22l9.38,3.14c5.3,1,10.67,2,16,1.57s10.83-2.44,14.3-6.57C478.83,141.1,478.72,132.23,474.91,125.79Z"
						style={{ fill: 'rgb(38, 50, 56)', transformOrigin: '454.977px 133.052px' }}
						id="el94j9mjklveq"
						className="animable"
					/>
					<path
						d="M480,154a7.26,7.26,0,0,0-2.43-5.92,5.24,5.24,0,0,0-6.17-.4l-1.79,1.57a6.3,6.3,0,0,0-4.53,5.73,6.89,6.89,0,0,0,3.88,6.3,7.64,7.64,0,0,0,7.45-.64A8.54,8.54,0,0,0,480,154Z"
						style={{ fill: 'rgb(38, 50, 56)', transformOrigin: '472.548px 154.412px' }}
						id="elj2vylbdke3q"
						className="animable"
					/>
					<path
						d="M477.08,160.24a3.22,3.22,0,0,0,.81-1.84,8.83,8.83,0,0,0-1.54-4.67,13.22,13.22,0,0,0-3.39-3.68c-1-.78-1.78-1.12-1.74-1.2a5.54,5.54,0,0,1,1.93.92,11.76,11.76,0,0,1,3.61,3.71,11.59,11.59,0,0,1,1.19,2.61,5.26,5.26,0,0,1,.28,2.35,2.69,2.69,0,0,1-.67,1.46C477.3,160.18,477.1,160.26,477.08,160.24Z"
						style={{ fill: 'rgb(69, 90, 100)', transformOrigin: '474.74px 154.546px' }}
						id="elr61dmqazy3"
						className="animable"
					/>
					<path
						d="M472,162.38a21.79,21.79,0,0,0,.54-6.44,20.93,20.93,0,0,0-1.87-6.17,5,5,0,0,1,1.06,1.62,13.63,13.63,0,0,1,1,9.18A5.33,5.33,0,0,1,472,162.38Z"
						style={{ fill: 'rgb(69, 90, 100)', transformOrigin: '471.891px 156.075px' }}
						id="el2ehshz9tlbk"
						className="animable"
					/>
					<path
						d="M440.49,171.93c.58-3.37,1.87-10.6,1.87-10.58s-7.73-2.07-8.36-12.47c-.32-5.17.75-13.5,1.84-20.29,1-6.11,7.46-13.65,15-12.52l3.41.12,12.07,7.07a3.61,3.61,0,0,1,2.87,4l-6.61,43.69-18.71,36.61-4.23-26Z"
						style={{ fill: 'rgb(255, 190, 157)', transformOrigin: '451.581px 161.758px' }}
						id="elo9bc1o995si"
						className="animable"
					/>
					<path
						d="M439.32,136.05a1.32,1.32,0,0,0,1.14,1.44,1.26,1.26,0,0,0,1.47-1.05,1.33,1.33,0,0,0-1.14-1.45A1.26,1.26,0,0,0,439.32,136.05Z"
						style={{ fill: 'rgb(38, 50, 56)', transformOrigin: '440.625px 136.24px' }}
						id="elbsg9mauxats"
						className="animable"
					/>
					<path
						d="M437.76,132.74c.15.19,1.22-.45,2.63-.31s2.38.95,2.55.79-.05-.4-.47-.77a3.6,3.6,0,0,0-2-.84,3.47,3.47,0,0,0-2.11.46C437.87,132.36,437.69,132.66,437.76,132.74Z"
						style={{ fill: 'rgb(38, 50, 56)', transformOrigin: '440.372px 132.416px' }}
						id="eld6nociiez8k"
						className="animable"
					/>
					<path
						d="M445,144.71a9.37,9.37,0,0,0-2.26-.64c-.36-.07-.69-.17-.73-.43a1.8,1.8,0,0,1,.35-1c.42-.84.86-1.73,1.33-2.65,1.86-3.77,3.25-6.89,3.09-7a48,48,0,0,0-3.64,6.7l-1.29,2.66a2.1,2.1,0,0,0-.33,1.4.93.93,0,0,0,.55.59,2.56,2.56,0,0,0,.6.14A9.06,9.06,0,0,0,445,144.71Z"
						style={{ fill: 'rgb(38, 50, 56)', transformOrigin: '444.149px 138.852px' }}
						id="el0n8bd5u3g7x"
						className="animable"
					/>
					<path
						d="M442.36,161.35A26.23,26.23,0,0,0,456.44,159s-4.34,6.74-14.51,4.79Z"
						style={{ fill: 'rgb(235, 153, 110)', transformOrigin: '449.185px 161.572px' }}
						id="el1yc9vvnvhv7"
						className="animable"
					/>
					<path
						d="M438.87,127.75c.22.35,1.23.11,2.4.21s2.14.42,2.4.11c.12-.15,0-.49-.41-.84a3.5,3.5,0,0,0-3.87-.25C439,127.27,438.78,127.59,438.87,127.75Z"
						style={{ fill: 'rgb(38, 50, 56)', transformOrigin: '441.282px 127.357px' }}
						id="el9ykjsmtpyli"
						className="animable"
					/>
					<path
						d="M455.49,137.08a1.32,1.32,0,0,1-1.44,1.15,1.27,1.27,0,0,1-1.19-1.36,1.31,1.31,0,0,1,1.44-1.15A1.27,1.27,0,0,1,455.49,137.08Z"
						style={{ fill: 'rgb(38, 50, 56)', transformOrigin: '454.175px 136.975px' }}
						id="el9o6s63h6d0h"
						className="animable"
					/>
					<path
						d="M456.48,133.92c-.19.15-1.08-.72-2.49-.91s-2.53.38-2.66.19.14-.37.63-.64a3.64,3.64,0,0,1,2.17-.36,3.53,3.53,0,0,1,1.95.93C456.46,133.52,456.57,133.85,456.48,133.92Z"
						style={{ fill: 'rgb(38, 50, 56)', transformOrigin: '453.904px 133.05px' }}
						id="eltfryvcvv7lf"
						className="animable"
					/>
					<path
						d="M456.27,129.1c-.29.28-1.22-.18-2.38-.35s-2.19-.08-2.37-.44c-.08-.18.12-.48.59-.72a3.24,3.24,0,0,1,2-.26,3.32,3.32,0,0,1,1.82.89C456.3,128.61,456.4,129,456.27,129.1Z"
						style={{ fill: 'rgb(38, 50, 56)', transformOrigin: '453.914px 128.231px' }}
						id="elcjxq62z5es"
						className="animable"
					/>
					<path
						d="M467,141.58l-2,1.55c-6.49-8.43-18.56-28.9-17.9-27.69a22.41,22.41,0,0,1,11,.47c5.5,1.83,15.79,6.29,14.61,12.93S467,141.58,467,141.58Z"
						style={{ fill: 'rgb(38, 50, 56)', transformOrigin: '459.939px 129.049px' }}
						id="el0wg1jd9ky82"
						className="animable"
					/>
					<path
						d="M471.24,148.11a15.08,15.08,0,0,1,.18-1.62,25,25,0,0,0,.08-4.41,30,30,0,0,0-4.62-13.65,48,48,0,0,0-9.71-10.81c-1.44-1.2-2.63-2.13-3.47-2.77a13.88,13.88,0,0,1-1.27-1s.14,0,.37.2.58.37,1,.67c.87.59,2.1,1.48,3.57,2.66a44.77,44.77,0,0,1,9.91,10.82,29.15,29.15,0,0,1,4.55,13.88,21.54,21.54,0,0,1-.23,4.46c-.07.52-.19.91-.24,1.19A1.27,1.27,0,0,1,471.24,148.11Z"
						style={{ fill: 'rgb(69, 90, 100)', transformOrigin: '462.145px 130.98px' }}
						id="el6wezlpyrsyd"
						className="animable"
					/>
					<path
						d="M456.86,114.08a1.86,1.86,0,0,1,.38.2c.25.14.6.35,1.05.64.89.58,2.17,1.45,3.68,2.63A58.61,58.61,0,0,1,467,122a33.87,33.87,0,0,1,5.31,6.29,15.78,15.78,0,0,1,1.77,3.92,14.61,14.61,0,0,1,.56,4,23.39,23.39,0,0,1-1,6.65c-.55,1.84-1.11,3.28-1.51,4.27-.2.49-.37.87-.48,1.13a2.5,2.5,0,0,1-.2.38A11,11,0,0,1,472,147c.34-1,.85-2.46,1.35-4.29a24,24,0,0,0,.92-6.55,14.88,14.88,0,0,0-.57-3.84,15.57,15.57,0,0,0-1.73-3.8,35.78,35.78,0,0,0-5.2-6.23,66.14,66.14,0,0,0-5-4.46c-1.47-1.2-2.72-2.11-3.58-2.73A11.54,11.54,0,0,1,456.86,114.08Z"
						style={{ fill: 'rgb(69, 90, 100)', transformOrigin: '465.75px 131.36px' }}
						id="el7ngb1dkfra9"
						className="animable"
					/>
					<path
						d="M467.15,141.77a6.52,6.52,0,0,1-.77-1l-2-2.86c-1.63-2.42-3.86-5.79-6.29-9.53s-4.62-7.13-6.18-9.61l-1.83-2.94a7.94,7.94,0,0,1-.62-1.11,6.06,6.06,0,0,1,.77,1l2,2.86c1.63,2.42,3.86,5.79,6.3,9.53s4.62,7.14,6.18,9.61l1.83,2.95A6.16,6.16,0,0,1,467.15,141.77Z"
						style={{ fill: 'rgb(69, 90, 100)', transformOrigin: '458.305px 128.245px' }}
						id="elunaa2spzo7d"
						className="animable"
					/>
					<path
						d="M465.09,144.28c.08-.76,1.14-2.48,1.9-2.52,2-.12,5.66.39,5.19,5.51-.63,7-7.51,4.91-7.51,4.71S464.83,146.91,465.09,144.28Z"
						style={{ fill: 'rgb(255, 190, 157)', transformOrigin: '468.446px 147.085px' }}
						id="elkxqmgiqq2gk"
						className="animable"
					/>
					<path
						d="M466.6,149.35s.11.1.31.21a1.19,1.19,0,0,0,.91.1,3,3,0,0,0,1.64-2.57,4.13,4.13,0,0,0-.19-1.78,1.39,1.39,0,0,0-.83-1,.64.64,0,0,0-.75.27c-.11.19-.08.33-.11.34s-.14-.13-.06-.41a.74.74,0,0,1,.31-.41.9.9,0,0,1,.69-.12,1.7,1.7,0,0,1,1.18,1.2,4,4,0,0,1,.24,2c-.17,1.41-1,2.66-2,2.86a1.31,1.31,0,0,1-1.1-.28C466.6,149.52,466.58,149.36,466.6,149.35Z"
						style={{ fill: 'rgb(235, 153, 110)', transformOrigin: '468.285px 147.011px' }}
						id="elwbx3v6sgvgh"
						className="animable"
					/>
					<path
						d="M481.51,403.08c-.14,1.1-2.85,31.85-2.85,31.85H464.57l-2.47-31.72Z"
						style={{ fill: 'rgb(255, 190, 157)', transformOrigin: '471.805px 419.005px' }}
						id="el3ewtjnbbs3q"
						className="animable"
					/>
					<path
						d="M464.53,430.35s9.9,7,14.68.95l1.26,2.94,5.62,21a2.81,2.81,0,0,1-1.58,3.37,2.73,2.73,0,0,1-2.88-.55c-4.07-3.88-19-18.19-18.82-20.13l.05-5.15a2.65,2.65,0,0,1,1.67-2.42Z"
						style={{ fill: '#048477', transformOrigin: '474.506px 444.576px' }}
						id="ellbv8hvudw1f"
						className="animable"
					/>
					<path
						d="M484,458.74,462.8,436.13v1.12a3.18,3.18,0,0,0,1,2.49c1.72,2.31,6.47,7.49,17.74,18.31a2.71,2.71,0,0,0,2.47.69Z"
						style={{ fill: 'rgb(38, 50, 56)', transformOrigin: '473.403px 447.468px' }}
						id="elrsl2fwo9rz"
						className="animable"
					/>
					<path
						d="M465.91,432.77a1.65,1.65,0,0,1,.46,2.15,1.59,1.59,0,0,1-2.13.48,1.74,1.74,0,0,1-.47-2.28,1.64,1.64,0,0,1,2.26-.25"
						style={{ fill: 'rgb(255, 255, 255)', transformOrigin: '465.059px 434.076px' }}
						id="elvhvbpyjea9b"
						className="animable"
					/>
					<path
						d="M477.91,447.16a8.24,8.24,0,0,1,2.32-2.08,8.5,8.5,0,0,1,3-.6c0-.06-.31-.27-.93-.35a4.2,4.2,0,0,0-2.38.43,4.16,4.16,0,0,0-1.78,1.64C477.88,446.74,477.84,447.14,477.91,447.16Z"
						style={{ fill: 'rgb(38, 50, 56)', transformOrigin: '480.555px 445.632px' }}
						id="elwjbcxxxqwm"
						className="animable"
					/>
					<path
						d="M479.81,450.71a21.37,21.37,0,0,1,2.35-1.16,22.55,22.55,0,0,1,2.59-.34c.08-.14-1.2-.69-2.76-.21S479.67,450.64,479.81,450.71Z"
						style={{ fill: 'rgb(38, 50, 56)', transformOrigin: '482.274px 449.759px' }}
						id="elzphiziw6ewi"
						className="animable"
					/>
					<path
						d="M474.37,442.61a27.6,27.6,0,0,1,3.5-2,25.71,25.71,0,0,1,3.94-.93c0-.15-1.94-.51-4.16.39A6,6,0,0,0,474.37,442.61Z"
						style={{ fill: 'rgb(38, 50, 56)', transformOrigin: '478.09px 441.046px' }}
						id="elj8lsprd0bgm"
						className="animable"
					/>
					<path
						d="M473.89,436.22c.08.16,1.54-.46,3.45-.53s3.42.37,3.48.21a6.74,6.74,0,0,0-6.93.32Z"
						style={{ fill: 'rgb(38, 50, 56)', transformOrigin: '477.355px 435.669px' }}
						id="elw2gs89e70f"
						className="animable"
					/>
					<path
						d="M478.43,435.63a2.47,2.47,0,0,0-.15-1A7.06,7.06,0,0,0,477,432.2a3.08,3.08,0,0,0-1.58-1.21,1.1,1.1,0,0,0-1.17.46,1.81,1.81,0,0,0-.19,1.26,3.85,3.85,0,0,0,4,2.88,4.47,4.47,0,0,0,3.88-3.1,1.54,1.54,0,0,0-.17-1.28,1,1,0,0,0-1.2-.35,3.87,3.87,0,0,0-1.56,1.21,8,8,0,0,0-1.4,2.36,2.48,2.48,0,0,0-.23,1,12.29,12.29,0,0,1,1.94-3.06,3.58,3.58,0,0,1,1.38-1,.57.57,0,0,1,.66.19,1,1,0,0,1,.06.85A4,4,0,0,1,478,435a3.35,3.35,0,0,1-3.41-2.4,1.28,1.28,0,0,1,.1-.88.59.59,0,0,1,.64-.27,2.83,2.83,0,0,1,1.35,1A10.37,10.37,0,0,1,478.43,435.63Z"
						style={{ fill: 'rgb(38, 50, 56)', transformOrigin: '478.015px 433.207px' }}
						id="ellccgpjmrunc"
						className="animable"
					/>
					<polygon
						points="404.14 393.07 406.57 432.33 423.63 432.21 426.51 391.42 404.14 393.07"
						style={{ fill: 'rgb(255, 190, 157)', transformOrigin: '415.325px 411.875px' }}
						id="el221744axekd"
						className="animable"
					/>
					<path
						d="M406.26,427.59v1.14S387,436.38,386.76,440l38.2,0-.54-12.71A21.82,21.82,0,0,1,406.26,427.59Z"
						style={{ fill: '#048477', transformOrigin: '405.86px 433.645px' }}
						id="eldo2ngzvdixw"
						className="animable"
					/>
					<path
						d="M420.56,431.34a1.56,1.56,0,0,1,1.08,1.78,1.5,1.5,0,0,1-1.75,1.09,1.65,1.65,0,0,1-1.14-1.89,1.56,1.56,0,0,1,1.95-.93"
						style={{ fill: 'rgb(255, 255, 255)', transformOrigin: '420.193px 432.775px' }}
						id="el92660eh0g1"
						className="animable"
					/>
					<path
						d="M425,440l-.13-3.05-36.56,1.16s-1.7.74-1.51,1.93Z"
						style={{ fill: 'rgb(38, 50, 56)', transformOrigin: '405.893px 438.495px' }}
						id="el21n7op61hj"
						className="animable"
					/>
					<path
						d="M406.11,428.84c0,.18.94.26,1.86.86s1.41,1.39,1.58,1.31-.11-1.15-1.23-1.85S406.07,428.66,406.11,428.84Z"
						style={{ fill: 'rgb(38, 50, 56)', transformOrigin: '407.852px 429.842px' }}
						id="elof4rqwv50ws"
						className="animable"
					/>
					<path
						d="M402.11,430.5c0,.18.76.49,1.4,1.28s.82,1.62,1,1.62.31-1.07-.5-2S402.11,430.32,402.11,430.5Z"
						style={{ fill: 'rgb(38, 50, 56)', transformOrigin: '403.378px 431.917px' }}
						id="elp93yzwakwpl"
						className="animable"
					/>
					<path
						d="M399.84,435.29c.17,0,.43-.89,0-1.91s-1.36-1.43-1.44-1.27.47.7.85,1.54S399.65,435.28,399.84,435.29Z"
						style={{ fill: 'rgb(38, 50, 56)', transformOrigin: '399.233px 433.683px' }}
						id="eljm0e425ehhh"
						className="animable"
					/>
					<path
						d="M426.51,271.57c-.51,1.47-20.74,70-20.74,70l-3.48,81.33,25.18,1.45,9-81.2,23-50.79-10.11-41.32H428.8Z"
						style={{ fill: 'rgb(38, 50, 56)', transformOrigin: '430.88px 337.695px' }}
						id="elj3nxj0wtea"
						className="animable"
					/>
					<path
						d="M444.43,251.06c.36,1,13.27,47.5,13.27,47.5l1.79,69.34v57.37h22.95l10.69-126.52s1.89-23-1.34-30.73-16.31-21.31-16.31-21.31Z"
						style={{ fill: 'rgb(38, 50, 56)', transformOrigin: '469.055px 335.99px' }}
						id="elykjxztan5v"
						className="animable"
					/>
					<path
						d="M446.28,255.82a11.11,11.11,0,0,1,.52,1.62c.33,1.13.77,2.63,1.29,4.44,1.16,3.83,2.7,9,4.41,14.63s3.08,11,3.88,14.79c.42,1.92.69,3.48.85,4.56a9.57,9.57,0,0,1,.19,1.69,11.57,11.57,0,0,1-.39-1.65c-.22-1.07-.55-2.62-1-4.51-.89-3.81-2.33-9-4-14.73s-3.25-10.9-4.26-14.68c-.51-1.89-.9-3.42-1.14-4.49A9.82,9.82,0,0,1,446.28,255.82Z"
						style={{ fill: 'rgb(69, 90, 100)', transformOrigin: '451.85px 276.685px' }}
						id="ele3eopk4pv35"
						className="animable"
					/>
					<path
						d="M454.2,255.82a3,3,0,0,0-1.44,1,1.85,1.85,0,0,0-.2,1.76,2,2,0,0,0,1.7,1.25,2,2,0,0,0,1.81-1.09,1.87,1.87,0,0,0,0-1.77,3,3,0,0,0-1.34-1.16s.2-.05.53.06a2.21,2.21,0,0,1,1.12.92,2.17,2.17,0,0,1,.15,2.16,2.36,2.36,0,0,1-4.39-.2,2.2,2.2,0,0,1,.35-2.14,2.23,2.23,0,0,1,1.21-.81C454,255.75,454.2,255.79,454.2,255.82Z"
						style={{ fill: 'rgb(69, 90, 100)', transformOrigin: '454.377px 258.063px' }}
						id="elyuipg30bmug"
						className="animable"
					/>
					<path
						d="M474.57,289.22a1.6,1.6,0,0,1,0,.35c0,.26,0,.59,0,1,0,.94,0,2.26,0,3.94,0,3.43,0,8.38-.11,14.5-.18,12.24-.73,29.13-1.45,47.79s-1.32,35.56-1.6,47.79c-.14,6.12-.23,11.07-.28,14.49,0,1.68,0,3-.06,3.94q0,.63,0,1a.18.18,0,1,1-.06,0c0-.26,0-.6,0-1,0-.94,0-2.26,0-3.94,0-3.43,0-8.38.11-14.5.19-12.24.73-29.14,1.45-47.8s1.32-35.55,1.6-47.78c.14-6.12.24-11.07.28-14.49,0-1.68.05-3,.06-3.94,0-.43,0-.76,0-1A1.35,1.35,0,0,1,474.57,289.22Z"
						style={{ fill: 'rgb(69, 90, 100)', transformOrigin: '472.72px 356.799px' }}
						id="el1zs11l63s7b"
						className="animable"
					/>
					<path
						d="M439.16,280.87a2,2,0,0,1-.11.35l-.39,1c-.37.93-.89,2.25-1.55,3.94-1.37,3.44-3.34,8.37-5.78,14.45s-5.22,13.42-7.88,21.68a139.2,139.2,0,0,0-6.35,27.43c-.53,5-.76,9.88-1.06,14.59s-.5,9.27-.74,13.61c-.45,8.67-.85,16.48-1.19,23.06s-.7,11.87-1,15.53c-.15,1.81-.26,3.22-.34,4.22-.05.46-.08.82-.11,1.09a2.91,2.91,0,0,1-.05.38,1.83,1.83,0,0,1,0-.38c0-.27,0-.64.05-1.1.06-1,.14-2.41.25-4.22.21-3.67.49-9,.8-15.54s.68-14.39,1.08-23.06c.23-4.34.46-8.9.71-13.62s.52-9.61,1.05-14.61A138.47,138.47,0,0,1,423,322.17c2.69-8.27,5.56-15.56,8-21.66s4.52-11,5.93-14.39c.7-1.68,1.25-3,1.64-3.91l.44-1A1.73,1.73,0,0,1,439.16,280.87Z"
						style={{ fill: 'rgb(69, 90, 100)', transformOrigin: '425.88px 351.535px' }}
						id="el6g15c0331jn"
						className="animable"
					/>
					<path
						d="M473.78,169.2s13.65,2.55,16.65,5.05,30.34,38.16,30.91,45.36-2.76,9.84-4.65,11.55S482.12,255,482.12,255l-10.05-16.67,28.24-16.87-13.63-23.31Z"
						style={{ fill: '#048477', transformOrigin: '496.737px 212.1px' }}
						id="eljbgpigs0aj"
						className="animable"
					/>
					<path
						d="M509.43,215.12A15.72,15.72,0,0,0,504,217a16,16,0,0,0-4.06,4.06,3.89,3.89,0,0,1,.75-1.58,10.2,10.2,0,0,1,7-4.37A3.81,3.81,0,0,1,509.43,215.12Z"
						style={{ fill: '#048477', transformOrigin: '504.685px 218.037px' }}
						id="elk8aueumr42n"
						className="animable"
					/>
					<path
						d="M440.7,170.93l3.62,32.84,18.47-37,11.91,12.1s7.31,43.25,7.42,43.56-5,24.74-5,24.74l-33.85,6.55-10.33-2.63-1.63-38.47-.72-29.46Z"
						style={{ fill: 'rgb(255, 255, 255)', transformOrigin: '456.356px 210.245px' }}
						id="el5i1vbogwfx3"
						className="animable"
					/>
					<path
						d="M436.31,172.33l-8.79,2a7.45,7.45,0,0,0-3.18,1.61c-7.5,6.42-23.72,20-23.72,20l3-24.1L388,170.39s-4.68,30.38-4.2,38.37c.57,9.28,8.89,11.56,21,6.57a158.48,158.48,0,0,0,21.67-10.68Z"
						style={{ fill: '#048477', transformOrigin: '410.038px 194.15px' }}
						id="elfzoe95jmc65"
						className="animable"
					/>
					<path
						d="M485.33,198.28l-1.61,28.8,1.62,5.6S500.5,261.3,503,280.25l-18.76,3s-5.12-24.45-8.72-36.57S461.27,229.46,457.67,217a57.71,57.71,0,0,1-2.27-22c0-.76,7.39-28.24,7.39-28.24l1.32-5.74,6.64,6.31L487.8,173Z"
						style={{ fill: '#048477', transformOrigin: '479.083px 222.135px' }}
						id="el6nbd3y95mmx"
						className="animable"
					/>
					<g id="elw16yeieuv6">
						<g
							style={{ opacity: '0.3', transformOrigin: '485.61px 253.422px' }}
							className="animable"
							id="eldn63k2cet6n"
						>
							<path
								d="M494.27,251.84a97.7,97.7,0,0,1-9.71,6.61c-.2.1-.46.21-.75.32a3,3,0,0,1-3.67-1.38c-.4-.73-.81-1.5-1.08-2.08-.69-1.49-1.44-3.28-2.11-4.83l2.11-.51,3.06,5.07,8.82-6,1.68-1.15Z"
								id="elmdaosgpwrat"
								className="animable"
								style={{ transformOrigin: '485.61px 253.422px' }}
							/>
						</g>
					</g>
					<path
						d="M440.86,167.69l-4.1,4.29-9.83,3.45s-2.4,29-1.1,35.18c1.26,5.94,3.66,13.16,3.86,21.69s-9.54,53.61-9.54,53.61l11.78,4.47s6-52.19,4.83-63.57,1.87-34.32,1.87-34.32Z"
						style={{ fill: '#048477', transformOrigin: '430.505px 229.035px' }}
						id="elnvr2afurgt"
						className="animable"
					/>
					<path
						d="M463.16,223a3.32,3.32,0,0,1-.43-.47,9.77,9.77,0,0,1-1-1.55,15.55,15.55,0,0,1-1.53-6.56,61.87,61.87,0,0,1,.7-10,86.91,86.91,0,0,1,2.49-12,88.47,88.47,0,0,1,8-19.42l.16.29-4.17-.13h-.3l.18-.24,2.74-3.68.74-1a1.56,1.56,0,0,1,.28-.31,1.62,1.62,0,0,1-.21.36l-.68,1-2.62,3.77-.12-.24,4.17.05h.32l-.16.28a94.21,94.21,0,0,0-7.86,19.37,92.67,92.67,0,0,0-2.52,11.91,67.35,67.35,0,0,0-.8,9.9,16.23,16.23,0,0,0,1.34,6.48A23.19,23.19,0,0,0,463.16,223Z"
						style={{ fill: 'rgb(38, 50, 56)', transformOrigin: '466.038px 195.465px' }}
						id="el67n04c5e9nh"
						className="animable"
					/>
					<path
						d="M474.7,196.41a18.12,18.12,0,0,1-4.83.92,17.78,17.78,0,0,1-4.91.06,35.29,35.29,0,0,1,4.86-.58A33,33,0,0,1,474.7,196.41Z"
						style={{ fill: 'rgb(38, 50, 56)', transformOrigin: '469.83px 196.971px' }}
						id="el891aj9qkm9g"
						className="animable"
					/>
					<path
						d="M486.66,189.73a8.38,8.38,0,0,1-.21,1.46c-.13.94-.3,2.3-.46,4-.32,3.38-.64,8.05-1,13.21l-.8,13.23c-.12,1.69-.22,3.06-.31,4a7.18,7.18,0,0,1-.2,1.46,9.26,9.26,0,0,1,0-1.47c0-1.05.08-2.4.14-4,.13-3.39.34-8.07.65-13.24s.7-9.84,1.12-13.22c.2-1.68.43-3,.62-4A7.76,7.76,0,0,1,486.66,189.73Z"
						style={{ fill: 'rgb(38, 50, 56)', transformOrigin: '485.155px 208.41px' }}
						id="el4w9np3lxmlk"
						className="animable"
					/>
					<path
						d="M508.49,222.42c-.05.15-1.89-.44-4.25-.61s-4.26.13-4.29,0a9.06,9.06,0,0,1,4.33-.49A9.15,9.15,0,0,1,508.49,222.42Z"
						style={{ fill: 'rgb(38, 50, 56)', transformOrigin: '504.22px 221.844px' }}
						id="elzdpd6rz7evs"
						className="animable"
					/>
					<path
						d="M400.72,195.38a15.55,15.55,0,0,1-1.38,3.73,15.15,15.15,0,0,1-1.85,3.52,15,15,0,0,1,1.38-3.73A15.7,15.7,0,0,1,400.72,195.38Z"
						style={{ fill: 'rgb(38, 50, 56)', transformOrigin: '399.105px 199.005px' }}
						id="elqjf2n4et15c"
						className="animable"
					/>
					<path
						d="M425.24,185.77c.14,0,.37,5.66.5,12.65s.13,12.66,0,12.66-.37-5.66-.51-12.65S425.1,185.78,425.24,185.77Z"
						style={{ fill: 'rgb(38, 50, 56)', transformOrigin: '425.484px 198.425px' }}
						id="elocxmf74u4xc"
						className="animable"
					/>
					<path
						d="M436.9,209.55a7.53,7.53,0,0,1-.13-1.46c0-1-.08-2.38-.14-4-.1-3.37-.21-8-.29-13.16s-.12-9.8-.13-13.17c0-1.61,0-2.95,0-4a7.69,7.69,0,0,1,.08-1.46,7.53,7.53,0,0,1,.13,1.46c0,1,.08,2.38.14,4,.1,3.37.2,8,.28,13.16s.13,9.79.14,13.16c0,1.61,0,3,0,4A8.94,8.94,0,0,1,436.9,209.55Z"
						style={{ fill: 'rgb(38, 50, 56)', transformOrigin: '436.594px 190.925px' }}
						id="elh801yhs9am"
						className="animable"
					/>
					<path
						d="M510.93,235.3a4,4,0,0,1-.54.42l-1.6,1.15-6,4.21-20.52,14.17-.22.16-.14-.23-4.5-7.46-5.54-9.22-.13-.22.21-.13,20.26-12,5.94-3.46,1.6-.91a3.71,3.71,0,0,1,.57-.29,3.17,3.17,0,0,1-.53.37l-1.55,1-5.86,3.58L472.2,238.59l.09-.35c1.75,2.91,3.63,6,5.56,9.21l4.49,7.46-.37-.08c8.22-5.58,15.43-10.48,20.62-14l6.1-4.08,1.65-1.08A4.57,4.57,0,0,1,510.93,235.3Z"
						style={{ fill: 'rgb(38, 50, 56)', transformOrigin: '491.335px 238.45px' }}
						id="elrbmtmeauh8"
						className="animable"
					/>
					<g id="el12pvcg4zz1pi">
						<g
							style={{ opacity: '0.3', transformOrigin: '467.975px 197.52px' }}
							className="animable"
							id="ellgkh22rakus"
						>
							<path
								d="M461.79,220.86A39,39,0,0,1,463,208.07a125,125,0,0,1,12.62-33.43,3.71,3.71,0,0,0-4.26.48,10.14,10.14,0,0,0-2.54,3.78,92.4,92.4,0,0,0-8.25,29.89,28.86,28.86,0,0,0-.15,6c.2,2.1.2,4.3,1.5,5.95"
								id="el8e6xbl61i9o"
								className="animable"
								style={{ transformOrigin: '467.975px 197.52px' }}
							/>
						</g>
					</g>
					<g id="eluj2uqfovv6d">
						<g
							style={{ opacity: '0.3', transformOrigin: '424.269px 196.56px' }}
							className="animable"
							id="el778ba7h0cjl"
						>
							<path
								d="M425.21,187.87c-3.11,5.4-2.7,11.9.27,17.38,0-6,0-11.25-.36-17.3"
								id="elgt3z0uhyuhd"
								className="animable"
								style={{ transformOrigin: '424.269px 196.56px' }}
							/>
						</g>
					</g>
					<path
						d="M449.39,147.81a1.24,1.24,0,0,0-.57-.87,1.46,1.46,0,0,0-.66-.1,15.7,15.7,0,0,0-3.85.55l-.07.2a8.35,8.35,0,0,0,.35,1.65,3,3,0,0,0,.92,1.39,2.45,2.45,0,0,0,2.91,0A2.8,2.8,0,0,0,449.39,147.81Z"
						style={{ fill: 'rgb(235, 153, 110)', transformOrigin: '446.852px 148.972px' }}
						id="el471l4imk9t"
						className="animable"
					/>
					<path
						d="M445.53,150.87a5.42,5.42,0,0,0,1.62-.1,2.51,2.51,0,0,0,1.35-1,2.44,2.44,0,0,0,.36-1.94,1.22,1.22,0,0,0-.5-.77,1.44,1.44,0,0,0-.9,0l-1.8.34a5.75,5.75,0,0,1-1.7.19,5.65,5.65,0,0,1,1.62-.55l1.78-.45a1.81,1.81,0,0,1,1.23,0,1.66,1.66,0,0,1,.77,1.09,2.83,2.83,0,0,1-.47,2.35,2.75,2.75,0,0,1-1.65,1A2,2,0,0,1,445.53,150.87Z"
						style={{ fill: 'rgb(38, 50, 56)', transformOrigin: '446.7px 148.817px' }}
						id="eldrv68akdz5s"
						className="animable"
					/>
					<path
						d="M467.35,151.87a1.78,1.78,0,0,0-1.88-1.13h0a1.54,1.54,0,0,0-1.11.68,1.79,1.79,0,0,0,.19,2.11,1.73,1.73,0,0,0,2.08.31A1.69,1.69,0,0,0,467.35,151.87Z"
						style={{ fill: 'rgb(250, 250, 250)', transformOrigin: '465.769px 152.391px' }}
						id="el7uilf6kn4no"
						className="animable"
					/>
					<path
						d="M472.51,239.36l-11.57-10.25-9.77.84s.34,1.6,1.69,1.85,6.23.34,6.23.34l2.29,2.27-5.83-.58-7.41,4.37s-.17,1.35,1.77,1a38,38,0,0,0,5.64-1.85l4.31.25-4.7.68-6.09,4.29a3.19,3.19,0,0,0,3.2.85c1.94-.59,4.29-1.52,4.29-1.52l5.31-.51-5.31,1.35-3.95,4.89a2.23,2.23,0,0,0,2.55,0,26.37,26.37,0,0,0,2.25-1.77l5.89-.26s6.51,4.72,8.77,5.23,7-.87,7-.87Z"
						style={{ fill: 'rgb(255, 190, 157)', transformOrigin: '463.603px 240.027px' }}
						id="elzrh69ua45fi"
						className="animable"
					/>
				</g>
				<g
					id="freepik--job-offers--inject-58"
					className="animable"
					style={{ transformOrigin: '378.18px 237.985px' }}
				>
					<rect
						x="522.54"
						y="287.59"
						width="179.29"
						height="100.25"
						style={{ fill: 'rgb(250, 250, 250)', transformOrigin: '612.185px 337.715px' }}
						id="el2h5rhvm8ce7"
						className="animable"
					/>
					<path
						d="M701.83,387.84s0-.62,0-1.8,0-2.94,0-5.23c0-4.6,0-11.36,0-20.05,0-17.35-.06-42.39-.11-73.17l.2.2-179.28.06h0l.26-.26c0,35.45,0,69.36,0,100.25l-.24-.24,129,.12,37,.06,9.88,0,2.55,0h.87l-.84,0h-2.53l-9.82,0-37,.07-129.11.12h-.25v-.25c0-30.89,0-64.8,0-100.25v-.26h.27l179.28.06h.2v.2c0,30.83-.08,55.91-.11,73.3,0,8.66,0,15.41,0,20,0,2.28,0,4,0,5.2S701.83,387.84,701.83,387.84Z"
						style={{ fill: 'rgb(38, 50, 56)', transformOrigin: '612.285px 337.59px' }}
						id="el8nigb753nns"
						className="animable"
					/>
					<rect
						x="544.45"
						y="311.48"
						width="25.35"
						height="25.35"
						style={{ fill: 'rgb(69, 90, 100)', transformOrigin: '557.125px 324.155px' }}
						id="elkbhusn5u1g"
						className="animable"
					/>
					<path
						d="M604.61,315c0,.15-5.92.27-13.21.27s-13.22-.12-13.22-.27,5.92-.26,13.22-.26S604.61,314.9,604.61,315Z"
						style={{ fill: '#048477', transformOrigin: '591.395px 315.005px' }}
						id="elcrb2z5l4cgp"
						className="animable"
					/>
					<path
						d="M689,324.16c0,.14-24.82.26-55.42.26s-55.42-.12-55.42-.26,24.81-.26,55.42-.26S689,324,689,324.16Z"
						style={{ fill: 'rgb(38, 50, 56)', transformOrigin: '633.58px 324.16px' }}
						id="elm7s5qdc76pe"
						className="animable"
					/>
					<path
						d="M651.73,334.38c0,.14-16.31.26-36.41.26s-36.42-.12-36.42-.26,16.3-.26,36.42-.26S651.73,334.24,651.73,334.38Z"
						style={{ fill: 'rgb(38, 50, 56)', transformOrigin: '615.315px 334.38px' }}
						id="elzkb2wpw9cge"
						className="animable"
					/>
					<rect
						x="541.07"
						y="351.06"
						width="145.97"
						height="23.5"
						rx="11.75"
						style={{ fill: '#048477', transformOrigin: '614.055px 362.81px' }}
						id="el43sdx93m2a3"
						className="animable"
					/>
					<path
						d="M591.54,364.45h-3l-.57,1.39h-1.54l2.89-6.47h1.48l2.89,6.47h-1.57Zm-.47-1.13-1-2.48-1,2.48Z"
						style={{ fill: 'rgb(255, 255, 255)', transformOrigin: '590.06px 362.605px' }}
						id="el2hgqymgc5qr"
						className="animable"
					/>
					<path
						d="M600,361.72c0,1.44-1.08,2.34-2.81,2.34h-1.3v1.78h-1.5v-6.47h2.8C598.89,359.37,600,360.26,600,361.72Zm-1.51,0c0-.71-.47-1.13-1.38-1.13h-1.22v2.25h1.22C598,362.84,598.46,362.42,598.46,361.72Z"
						style={{ fill: 'rgb(255, 255, 255)', transformOrigin: '597.195px 362.605px' }}
						id="elhr0systt6bb"
						className="animable"
					/>
					<path
						d="M606.65,361.72c0,1.44-1.08,2.34-2.81,2.34h-1.3v1.78H601v-6.47h2.8C605.57,359.37,606.65,360.26,606.65,361.72Zm-1.52,0c0-.71-.46-1.13-1.37-1.13h-1.22v2.25h1.22C604.67,362.84,605.13,362.42,605.13,361.72Z"
						style={{ fill: 'rgb(255, 255, 255)', transformOrigin: '603.825px 362.605px' }}
						id="elgu4s7xtfl2i"
						className="animable"
					/>
					<path
						d="M607.72,359.37h1.49v5.25h3.25v1.22h-4.74Z"
						style={{ fill: 'rgb(255, 255, 255)', transformOrigin: '610.09px 362.605px' }}
						id="elc9mymmmk6p4"
						className="animable"
					/>
					<path
						d="M615.74,363.55v2.29h-1.5v-2.31l-2.5-4.16h1.59l1.73,2.87,1.73-2.87h1.47Z"
						style={{ fill: 'rgb(255, 255, 255)', transformOrigin: '615px 362.605px' }}
						id="el7qe6jxusiub"
						className="animable"
					/>
					<path
						d="M627.44,359.37v6.47h-1.23L623,361.91v3.93h-1.48v-6.47h1.24L626,363.3v-3.93Z"
						style={{ fill: 'rgb(255, 255, 255)', transformOrigin: '624.48px 362.605px' }}
						id="el6laxqh8rhxm"
						className="animable"
					/>
					<path
						d="M628.57,362.6a3.55,3.55,0,1,1,3.55,3.35A3.35,3.35,0,0,1,628.57,362.6Zm5.57,0a2,2,0,1,0-2,2.08A2,2,0,0,0,634.14,362.6Z"
						style={{ fill: 'rgb(255, 255, 255)', transformOrigin: '632.114px 362.403px' }}
						id="el0j1v9qk5tglm"
						className="animable"
					/>
					<path
						d="M646.36,359.37l-2.12,6.47h-1.61l-1.42-4.38-1.47,4.38h-1.6L636,359.37h1.55l1.47,4.55,1.52-4.55h1.39l1.48,4.58,1.5-4.58Z"
						style={{ fill: 'rgb(255, 255, 255)', transformOrigin: '641.18px 362.605px' }}
						id="elafb9hi1lg9c"
						className="animable"
					/>
					<rect
						x="54.44"
						y="217.93"
						width="132.71"
						height="74.2"
						style={{ fill: 'rgb(250, 250, 250)', transformOrigin: '120.795px 255.03px' }}
						id="eljtx6powcnr8"
						className="animable"
					/>
					<path
						d="M187.15,292.14s0-.46,0-1.34,0-2.18,0-3.88c0-3.41,0-8.41-.05-14.84,0-12.85-.07-31.37-.11-54.15l.2.2-132.7.06h0l.26-.26c0,26.24,0,51.34,0,74.21l-.24-.24,95.42.11,27.43.07,7.32,0h1.89l.65,0h-.62l-1.87,0-7.26,0-27.37.06-95.59.12H54.2v-.24c0-22.87,0-48,0-74.21v-.26h.27l132.7.07h.2v.19c0,22.83-.08,41.4-.11,54.27,0,6.41,0,11.4-.05,14.8,0,1.68,0,3,0,3.84S187.15,292.14,187.15,292.14Z"
						style={{ fill: 'rgb(38, 50, 56)', transformOrigin: '120.785px 254.905px' }}
						id="ell3ax5rdvm5b"
						className="animable"
					/>
					<rect
						x="70.66"
						y="235.62"
						width="18.76"
						height="18.76"
						style={{ fill: 'rgb(69, 90, 100)', transformOrigin: '80.04px 245px' }}
						id="elyorxcuux28b"
						className="animable"
					/>
					<path
						d="M115.19,238.26a93.38,93.38,0,0,1-9.78.26,93.38,93.38,0,0,1-9.78-.26,90.21,90.21,0,0,1,9.78-.26A90.21,90.21,0,0,1,115.19,238.26Z"
						style={{ fill: '#048477', transformOrigin: '105.41px 238.258px' }}
						id="elz3ejvhqq12"
						className="animable"
					/>
					<path
						d="M177.66,245c0,.15-18.36.26-41,.26s-41-.11-41-.26,18.36-.26,41-.26S177.66,244.86,177.66,245Z"
						style={{ fill: 'rgb(38, 50, 56)', transformOrigin: '136.66px 245px' }}
						id="eljy4r0l0cdun"
						className="animable"
					/>
					<path
						d="M150.06,252.57c0,.14-12.06.26-26.95.26s-26.95-.12-26.95-.26,12.06-.26,27-.26S150.06,252.42,150.06,252.57Z"
						style={{ fill: 'rgb(38, 50, 56)', transformOrigin: '123.11px 252.57px' }}
						id="elaspzum2wtfc"
						className="animable"
					/>
					<rect
						x="68.16"
						y="264.91"
						width="108.04"
						height="17.39"
						rx="8.7"
						style={{ fill: '#048477', transformOrigin: '122.18px 273.605px' }}
						id="el5heyy6mazzm"
						className="animable"
					/>
					<path
						d="M105.52,274.83h-2.23l-.43,1h-1.13l2.13-4.8H105l2.15,4.8h-1.17Zm-.35-.84-.76-1.84-.76,1.84Z"
						style={{ fill: 'rgb(255, 255, 255)', transformOrigin: '104.44px 273.43px' }}
						id="elzwwqlke5ojh"
						className="animable"
					/>
					<path
						d="M111.76,272.8c0,1.07-.8,1.73-2.08,1.73h-1v1.33H107.6v-4.8h2.08C111,271.06,111.76,271.72,111.76,272.8Zm-1.12,0c0-.53-.35-.84-1-.84h-.91v1.67h.91C110.29,273.63,110.64,273.32,110.64,272.8Z"
						style={{ fill: 'rgb(255, 255, 255)', transformOrigin: '109.68px 273.46px' }}
						id="elycosje9ai89"
						className="animable"
					/>
					<path
						d="M116.7,272.8c0,1.07-.8,1.73-2.08,1.73h-1v1.33h-1.11v-4.8h2.07C115.9,271.06,116.7,271.72,116.7,272.8Zm-1.12,0c0-.53-.34-.84-1-.84h-.9v1.67h.9C115.24,273.63,115.58,273.32,115.58,272.8Z"
						style={{ fill: 'rgb(255, 255, 255)', transformOrigin: '114.605px 273.46px' }}
						id="elp8wcpgmlzrd"
						className="animable"
					/>
					<path
						d="M117.49,271.06h1.11V275H121v.91h-3.51Z"
						style={{ fill: 'rgb(255, 255, 255)', transformOrigin: '119.245px 273.485px' }}
						id="eljzbht21e3l"
						className="animable"
					/>
					<path
						d="M123.43,274.16v1.7h-1.11v-1.72l-1.85-3.08h1.17l1.29,2.13,1.28-2.13h1.09Z"
						style={{ fill: 'rgb(255, 255, 255)', transformOrigin: '122.885px 273.46px' }}
						id="elyusmizxx8kb"
						className="animable"
					/>
					<path
						d="M132.09,271.06v4.8h-.91l-2.39-2.92v2.92H127.7v-4.8h.91L131,274v-2.91Z"
						style={{ fill: 'rgb(255, 255, 255)', transformOrigin: '129.895px 273.46px' }}
						id="el4sx6mwv7d8v"
						className="animable"
					/>
					<path
						d="M132.93,273.46a2.62,2.62,0,1,1,2.62,2.48A2.48,2.48,0,0,1,132.93,273.46Zm4.12,0a1.5,1.5,0,1,0-1.5,1.53A1.47,1.47,0,0,0,137.05,273.46Z"
						style={{ fill: 'rgb(255, 255, 255)', transformOrigin: '135.546px 273.322px' }}
						id="elbitso2f1sha"
						className="animable"
					/>
					<path
						d="M146.1,271.06l-1.57,4.8h-1.19l-1.06-3.25-1.09,3.25H140l-1.58-4.8h1.15l1.08,3.37,1.13-3.37h1l1.1,3.4,1.12-3.4Z"
						style={{ fill: 'rgb(255, 255, 255)', transformOrigin: '142.26px 273.46px' }}
						id="eliv0wcbiqamd"
						className="animable"
					/>
					<rect
						x="524.52"
						y="88.3"
						width="145.6"
						height="81.41"
						style={{ fill: 'rgb(250, 250, 250)', transformOrigin: '597.32px 129.005px' }}
						id="elf9s46d5jfh"
						className="animable"
					/>
					<path
						d="M670.12,169.71s0-.5,0-1.47,0-2.39,0-4.25c0-3.74,0-9.23-.05-16.28,0-14.09-.06-34.42-.1-59.41l.2.2-145.59.06h0l.26-.26c0,28.79,0,56.32,0,81.41l-.24-.24,104.7.11,30.09.07,8,0,2.07,0h0l-2,0-8,0-30,.07L524.52,170h-.24v-.24c0-25.09,0-52.62,0-81.41V88h.27l145.59.06h.2v.2c-.05,25-.08,45.41-.11,59.53,0,7,0,12.51-.05,16.24,0,1.84,0,3.25,0,4.22S670.12,169.71,670.12,169.71Z"
						style={{ fill: 'rgb(38, 50, 56)', transformOrigin: '597.31px 129px' }}
						id="ellm9bjfiab78"
						className="animable"
					/>
					<rect
						x="542.31"
						y="107.7"
						width="20.58"
						height="20.58"
						style={{ fill: 'rgb(69, 90, 100)', transformOrigin: '552.6px 117.99px' }}
						id="el1jdcxjshol2"
						className="animable"
					/>
					<path
						d="M591.17,110.6c0,.14-4.81.26-10.73.26s-10.73-.12-10.73-.26a213.34,213.34,0,0,1,21.46,0Z"
						style={{ fill: '#048477', transformOrigin: '580.44px 110.595px' }}
						id="el5sxn5fk9hd8"
						className="animable"
					/>
					<path
						d="M659.71,118c0,.15-20.15.27-45,.27s-45-.12-45-.27,20.14-.26,45-.26S659.71,117.85,659.71,118Z"
						style={{ fill: 'rgb(38, 50, 56)', transformOrigin: '614.71px 118.005px' }}
						id="eld8qg5kgum3f"
						className="animable"
					/>
					<path
						d="M629.43,126.3c0,.14-13.24.26-29.57.26s-29.57-.12-29.57-.26,13.24-.26,29.57-.26S629.43,126.15,629.43,126.3Z"
						style={{ fill: 'rgb(38, 50, 56)', transformOrigin: '599.86px 126.3px' }}
						id="elxv6fewi1ezh"
						className="animable"
					/>
					<path
						d="M648.56,158.92H549.11a9.54,9.54,0,0,1-9.54-9.54h0a9.54,9.54,0,0,1,9.54-9.54h99.45a9.54,9.54,0,0,1,9.54,9.54h0A9.54,9.54,0,0,1,648.56,158.92Z"
						style={{ fill: '#048477', transformOrigin: '598.835px 149.38px' }}
						id="elx2x294vhod"
						className="animable"
					/>
					<path
						d="M580.55,150.72h-2.44l-.46,1.12H576.4l2.34-5.25h1.2l2.35,5.25H581Zm-.38-.93-.83-2-.84,2Z"
						style={{ fill: 'rgb(255, 255, 255)', transformOrigin: '579.345px 149.215px' }}
						id="ela67ajy5tc6s"
						className="animable"
					/>
					<path
						d="M587.4,148.5c0,1.17-.88,1.89-2.28,1.89h-1.06v1.45h-1.22v-5.25h2.28C586.52,146.59,587.4,147.32,587.4,148.5Zm-1.23,0c0-.58-.38-.92-1.12-.92h-1v1.82h1C585.79,149.4,586.17,149.07,586.17,148.5Z"
						style={{ fill: 'rgb(255, 255, 255)', transformOrigin: '585.12px 149.215px' }}
						id="elvcp5jrn7n1a"
						className="animable"
					/>
					<path
						d="M592.82,148.5c0,1.17-.87,1.89-2.28,1.89h-1.06v1.45h-1.21v-5.25h2.27C592,146.59,592.82,147.32,592.82,148.5Zm-1.23,0c0-.58-.37-.92-1.12-.92h-1v1.82h1C591.22,149.4,591.59,149.07,591.59,148.5Z"
						style={{ fill: 'rgb(255, 255, 255)', transformOrigin: '590.545px 149.215px' }}
						id="el6l9br35j6tn"
						className="animable"
					/>
					<path
						d="M593.69,146.59h1.22v4.26h2.63v1h-3.85Z"
						style={{ fill: 'rgb(255, 255, 255)', transformOrigin: '595.615px 149.22px' }}
						id="eld21l0f9gihp"
						className="animable"
					/>
					<path
						d="M600.21,150v1.86H599V150l-2-3.38h1.29l1.4,2.33,1.41-2.33h1.19Z"
						style={{ fill: 'rgb(255, 255, 255)', transformOrigin: '599.645px 149.24px' }}
						id="el31uvv32250v"
						className="animable"
					/>
					<path
						d="M609.71,146.59v5.25h-1l-2.62-3.19v3.19h-1.2v-5.25h1l2.61,3.19v-3.19Z"
						style={{ fill: 'rgb(255, 255, 255)', transformOrigin: '607.3px 149.215px' }}
						id="el2g452hg9nwb"
						className="animable"
					/>
					<path
						d="M610.63,149.22a2.88,2.88,0,1,1,2.87,2.71A2.71,2.71,0,0,1,610.63,149.22Zm4.52,0a1.65,1.65,0,1,0-1.65,1.68A1.61,1.61,0,0,0,615.15,149.22Z"
						style={{ fill: 'rgb(255, 255, 255)', transformOrigin: '613.505px 149.052px' }}
						id="elpw827ep9dqk"
						className="animable"
					/>
					<path
						d="M625.07,146.59l-1.72,5.25H622l-1.15-3.56-1.2,3.56h-1.3l-1.72-5.25h1.26l1.18,3.69,1.24-3.69h1.13l1.2,3.72,1.22-3.72Z"
						style={{ fill: 'rgb(255, 255, 255)', transformOrigin: '620.85px 149.215px' }}
						id="el1t3ow3jk1tx"
						className="animable"
					/>
				</g>
				<defs>
					{' '}
					<filter id="active" height="200%">
						{' '}
						<feMorphology in="SourceAlpha" result="DILATED" operator="dilate" radius={2} />{' '}
						<feFlood floodColor="#32DFEC" floodOpacity={1} result="PINK" />{' '}
						<feComposite in="PINK" in2="DILATED" operator="in" result="OUTLINE" />{' '}
						<feMerge>
							{' '}
							<feMergeNode in="OUTLINE" /> <feMergeNode in="SourceGraphic" />{' '}
						</feMerge>{' '}
					</filter>{' '}
					<filter id="hover" height="200%">
						{' '}
						<feMorphology in="SourceAlpha" result="DILATED" operator="dilate" radius={2} />{' '}
						<feFlood floodColor="#ff0000" floodOpacity="0.5" result="PINK" />{' '}
						<feComposite in="PINK" in2="DILATED" operator="in" result="OUTLINE" />{' '}
						<feMerge>
							{' '}
							<feMergeNode in="OUTLINE" /> <feMergeNode in="SourceGraphic" />{' '}
						</feMerge>{' '}
						<feColorMatrix
							type="matrix"
							values="0   0   0   0   0                0   1   0   0   0                0   0   0   0   0                0   0   0   1   0 "
						/>{' '}
					</filter>
				</defs>
			</svg>
		</div>
	);
};

export default HiringSVG;
