import React, { useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import styled from 'styled-components';
// import ServiceSlide from './ServiceSlide';
import ServiceSlide from './ServiceSlide';

import { Swiper, SwiperSlide } from 'swiper/react';

// import Swiper core and required modules
import SwiperCore, { Pagination, Navigation, Autoplay } from 'swiper/core';

import { Container, Grid, useMediaQuery } from '@material-ui/core';
import { servicesData } from '../data';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { screenThreshold } from '../utils/animationVariants';

// install Swiper modules
SwiperCore.use([ Pagination, Autoplay ]);

const StyledTypography = styled(Typography)`
	background:var(--light-slate);
	padding:8px 16px;
	border-radius:16px;
	width:fit-content;
    margin-bottom:24px;
    font-family: 'Open Sans','Comfortaa',Arial, Helvetica, sans-serif;
	
`;

const ServicesSlider = () => {
	const isMdSmall = useMediaQuery((theme) => theme.breakpoints.down('md'));
	const isSmSmall = useMediaQuery((theme) => theme.breakpoints.down('sm'));

	const { ref, inView } = useInView({ threshold: isMdSmall ? '0.1' : screenThreshold });
	return (
		<div ref={ref}>
			{/* <WhiteSpace /> */}
			{/* <SectionHeader first="Se" second="rvices" inView={inView} /> */}
			<Container>
				<Grid container justifyContent="center" alignItems="center" className="serviceContainer">
					<Grid item md={10} xs={12}>
						<div className="service-slider">
							<Swiper
								slidesPerView={1}
								autoplay={{ delay: 12000 }}
								spaceBetween={30}
								loop={true}
								pagination={{
									clickable: true
								}}
								navigation={false}
								className="mySwiper"
							>
								{servicesData.map((service, index) => (
									<SwiperSlide key={index}>
										<ServiceSlide service={service} index={index} />
									</SwiperSlide>
								))}
							</Swiper>
						</div>
					</Grid>
					<Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
						<StyledTypography align="center" variant={isSmSmall ? 'caption' : 'body1'} component="p">
							we work for any data, any data type and for all industries
						</StyledTypography>
					</Grid>
				</Grid>
			</Container>
		</div>
	);
};

export default ServicesSlider;
