import React from 'react';
import { Link } from 'gatsby';
import {
	Card,
	CardActionArea,
	CardActions,
	CardContent,
	CardMedia,
	Button,
	Typography,
	Avatar,
	IconButton
} from '@material-ui/core';
import styled from 'styled-components';

const StyledAvatar = styled(Avatar)`
	width: 60%;
	height:60%;
	border: 10px solid var(--teal-green);
	padding:32px 16px 0px 16px;
	margin-bottom: 32px;
	border-radius: 50%;
`;
const TeamStyledCard = styled(Card)`
	width:320px;
	margin-bottom:16px;
	border-radius: 20px;
	background:var(--white);
	box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.25);

`;

const TeamStyledCardActionArea = styled(CardActionArea)`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	border-radius: 30px;
	padding:16px 8px;
	padding-top:32px;
	cursor: default;
`;

const TeamFlexBox = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	i {
		font-size: 2rem;
	}
`;

const TeamCard = ({ teamMember }) => {
	const { name, position, imageURL, linkedInURL } = teamMember;
	return (
		<TeamStyledCard>
			<TeamStyledCardActionArea>
				{/* <CardMedia> */}
				<StyledAvatar src={imageURL} variant="rounded" />
				{/* </CardMedia> */}
				<CardContent style={{ width: '100%' }}>
					<TeamFlexBox>
						<div>
							<Typography variant="h5" component="h2" align="left">
								{name}
							</Typography>
							<Typography variant="body2" align="left" color="textSecondary" component="p">
								{position}
							</Typography>
						</div>
						<IconButton color="primary" href={linkedInURL} target="_blank">
							<i className="fab fa-linkedin" />
						</IconButton>
					</TeamFlexBox>
					{/* <IntroTypography variant="body2" component="p">
						Lizzzards are a widespread group of squamate reptiles, with over 6,000 species, ranging across
						all the continents except Antarctica
					</IntroTypography> */}
				</CardContent>
			</TeamStyledCardActionArea>
		</TeamStyledCard>
	);
};

export default TeamCard;
