import { Button, CircularProgress, TextField, useMediaQuery } from '@material-ui/core';
import React from 'react';
import { useEffect } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import { useState } from 'react';
import validator from 'validator';

const ContactForm = ({ inView }) => {
	const [ success, setSuccess ] = useState(false);
	const [ error, setError ] = useState(false);
	const [ errors, setErrors ] = useState({ email: '', message: '' });
	const [ loading, setLoading ] = useState(false);
	useEffect(
		() => {
			if (!inView) setErrors({ phone: '', email: '', message: '' });
		},
		[ inView ]
	);
	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setSuccess(false);
		setError(false);
	};
	const handleErrors = (formData) => {
		if (formData.get('phone') && !validator.isNumeric(formData.get('phone'))) {
			throw new Error('Invalid phone');
		} else if (formData.get('phone') && !validator.isLength(formData.get('phone'), { min: 10, max: 12 })) {
			throw new Error('Phone must be of 10 to 12 digit');
		} else if (!formData.get('email')) {
			throw new Error('Email is required');
		} else if (!validator.isEmail(formData.get('email'))) {
			throw new Error('Invalid email');
		} else if (!formData.get('message')) {
			throw new Error('Message is required');
		} else {
			setErrors({ phone: '', email: '', message: '' });
		}
	};
	const handleFormSubmit = async (e) => {
		e.preventDefault();
		setLoading(true);
		const formElement = document.getElementById('form');
		const formData = new URLSearchParams(new FormData(formElement));
		try {
			await handleErrors(formData);
			await fetch(process.env.GET_FORM_URL, {
				method: 'POST',
				body: formData
			});
			setLoading(false);
			setSuccess(true);
			formElement.reset();
		} catch (e) {
			setLoading(false);
			if (e.message.toLowerCase().includes('phone')) setErrors({ phone: e.message });
			else if (e.message.toLowerCase().includes('email')) setErrors({ email: e.message });
			else if (e.message.toLowerCase().includes('message')) setErrors({ message: e.message });
			else setError(true);
		}
	};
	const isMdSmall = useMediaQuery((theme) => theme.breakpoints.down('md'));
	return (
		<form className="contactForm" id="form" onSubmit={handleFormSubmit}>
			<TextField
				className="textField"
				variant="filled"
				fullWidth
				label="Name"
				name="name"
				error={!!errors.name}
				margin={isMdSmall ? 'dense' : 'normal'}
			/>
			<TextField
				className="textField"
				variant="filled"
				fullWidth
				label="Phone Number"
				name="phone"
				error={!!errors.phone}
				helperText={errors.phone}
				margin={isMdSmall ? 'dense' : 'normal'}
			/>
			<TextField
				className="textField"
				variant="filled"
				fullWidth
				label="Email*"
				name="email"
				error={!!errors.email}
				helperText={errors.email}
				margin={isMdSmall ? 'dense' : 'normal'}
			/>
			<TextField
				className="textField"
				variant="filled"
				fullWidth
				label="Message*"
				name="message"
				error={!!errors.message}
				helperText={errors.message}
				multiline
				minRows={3}
				maxRows={5}
				margin={isMdSmall ? 'dense' : 'normal'}
			/>
			<Button
				style={{ textTransform: 'none', marginTop: '8px' }}
				startIcon={loading && <CircularProgress size={18} color="secondary" />}
				type="submit"
				variant="contained"
				color="primary"
			>
				Submit
			</Button>
			<Snackbar open={success} autoHideDuration={6000} onClose={handleClose}>
				<Alert elevation={6} variant="filled" onClose={handleClose} severity="success">
					Thanks, we will connect you shortly
				</Alert>
			</Snackbar>
			<Snackbar open={error} autoHideDuration={6000} onClose={handleClose}>
				<Alert onClose={handleClose} elevation={6} variant="filled" severity="error">
					Something went wrong
				</Alert>
			</Snackbar>
		</form>
	);
};

export default ContactForm;
