import React from 'react';
import { Button, Card, CardActionArea, CardActions, CardContent, CardMedia, Typography } from '@material-ui/core';
import styled from 'styled-components';
import defaultBlogImage from '../../static/blog_post.gif';
import { Link } from 'gatsby';
const StyledBlogCard = styled(Card)`
	width:320px;
	margin-top:16px;
	border-radius: 20px;
	background:var(--white);
	box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.25);
`;

const StyledBlogCardActionArea = styled(CardActionArea)`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	border-radius: 30px;
	/* padding:8px 8px 16px 8px; */
`;

const BlogCard = ({ article, location }) => {
	const { imageURL, title, imageName, blogURL, publishedDate } = article;
	return (
		<StyledBlogCard>
			<StyledBlogCardActionArea href={blogURL}>
				<CardMedia
					className="blogCardMedia"
					component="img"
					alt={imageName}
					height="140"
					image={imageURL || defaultBlogImage}
					title="Contemplative Reptile"
				/>
				<CardContent>
					<Typography gutterBottom variant="h6" component="h2" className="blogCardTitle">
						{title}
					</Typography>
					<Typography variant="caption" gutterBottom color="textSecondary" component="p">
						{publishedDate}
					</Typography>
					<CardActions className="blogCardActions">
						{location.pathname === '/' ? (
							<Button href="/blogs" size="small" color="primary" variant="contained">
								All Blogs
							</Button>
						) : (
							<span />
						)}
						<Button href={blogURL} size="small" color="primary" variant="outlined">
							Read More
						</Button>
					</CardActions>
				</CardContent>
			</StyledBlogCardActionArea>
		</StyledBlogCard>
	);
};

export default BlogCard;
