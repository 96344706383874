import React from 'react';
import { Box, Container, Grid, IconButton, Typography } from '@material-ui/core';
import { TextLogo } from './Logo';
import { footerData } from '../data';

const Copyright = () => {
	return (
		<Typography variant="body1" align="center" className="colorLightestSlate">
			{'Copyright © '}
			{`2020 - `}
			{new Date().getFullYear()}
		</Typography>
	);
};

const FooterTop = () => {
	return (
		<Container>
			<Grid container justifyContent="space-between">
				<Grid item md={4} xs={12} className="footerTopGrid">
					<div className="footerLogo">
						<TextLogo />
					</div>
					<div className="flex-row">
						<span className="footerIcon">
							<i className="fa fa-map-marker-alt" />
						</span>
						<Typography variant="subtitle1" component="h6">
							{footerData['address']}
						</Typography>
					</div>
				</Grid>
				<Grid item md={4} xs={12} className="footerTopGrid">
					<div className="footerFlex">
						<IconButton className="footerIconButton" href={footerData['linkedIn']} target="_blank">
							<i className="fab fa-linkedin" />
						</IconButton>
						<IconButton className="footerIconButton" href={footerData['instagram']} target="_blank">
							<i className="fab fa-instagram" />
						</IconButton>
						<IconButton className="footerIconButton">
							<i className="fab fa-twitter" />
						</IconButton>
						<IconButton className="footerIconButton">
							<i className="fab fa-facebook" />
						</IconButton>
					</div>
				</Grid>
				<Grid item md={4} xs={12} className="footerTopGrid">
					<Typography variant="h5" component="h4" gutterBottom className="raechUsTitle">
						Reach Us
					</Typography>
					<div className="flex-row">
						<span className="footerIcon">
							<i className="fas fa-envelope" />
						</span>
						<a href={footerData['email']} target="_blank" style={{ textDecoration: 'none' }}>
							<Typography variant="subtitle1" component="h6">
								{footerData['display_email']}
							</Typography>
						</a>
					</div>
					<div className="flex-row">
						<span className="footerIcon">
							<i className="fas fa-phone" />
						</span>
						<a href={footerData['telephone']} style={{ textDecoration: 'none' }}>
							<Typography variant="subtitle1" component="h6">
								{footerData['display_mobile']}
							</Typography>
						</a>
					</div>
				</Grid>
			</Grid>
		</Container>
	);
};

const Footer = () => {
	return (
		<footer>
			<div className="footerBoxTop" component="span">
				<FooterTop />
			</div>
			<div className="footerBox">
				<Copyright />
			</div>
		</footer>
	);
};

export default Footer;
