import {
	Card,
	CardContent,
	CardMedia,
	CircularProgress,
	Container,
	Grid,
	Toolbar,
	Typography,
	useMediaQuery
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { navLinks } from '../data';
const StyledLoader = styled.div`
	width: 100vw;
	height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
`;
const Loader = ({ finishLoading }) => {
	const [ noOfCards, setNoOfCards ] = useState([ 1 ]);
	useEffect(() => {
		const timeout = setTimeout(() => finishLoading(), 1000);
		return () => clearTimeout(timeout);
	}, []);

	const isMdSmall = useMediaQuery((theme) => theme.breakpoints.down('md'));
	const isSmSmall = useMediaQuery((theme) => theme.breakpoints.down('sm'));
	const isXsSmall = useMediaQuery((theme) => theme.breakpoints.down('xs'));
	useEffect(() => {
		isXsSmall ? setNoOfCards([ 1 ]) : isSmSmall ? setNoOfCards([ 1, 2 ]) : setNoOfCards([ 1, 2, 3 ]);
	});

	return (
		<div
			style={{
				height: '100vh',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'space-between'
			}}
		>
			<Container
				style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '4px' }}
			>
				<Skeleton variant="rect" width="12rem" height="56px" />
				{isSmSmall ? (
					<Skeleton variant="rect" width="40px" height="3rem" />
				) : (
					<div style={{ display: 'flex', justifyContent: 'space-around' }}>
						{navLinks.map((link, i) => (
							<div style={{ marginLeft: '24px' }} key={i}>
								<Skeleton variant="rect" width="100px" height="2rem" />
							</div>
						))}
					</div>
				)}
			</Container>
			<Container
				style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', height: '100%' }}
			>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						justifyContent: 'center',
						marginTop: '24px'
					}}
				>
					<Typography gutterBottom>
						<Skeleton variant="rect" width="300px" height="2rem" />
					</Typography>
					<Typography gutterBottom>
						<Skeleton variant="rect" width="100px" height="1rem" />
					</Typography>
				</div>
				<Grid
					container
					justifyContent={isSmSmall ? 'center' : 'space-between'}
					spacing={2}
					style={{ paddingBottom: '2rem' }}
				>
					{noOfCards.map((item, i) => (
						<Grid item md={4} xs={12} key={i} style={{ maxWidth: '340px' }}>
							<Card>
								<Skeleton variant="rect" width="100%" height="8rem" />
								<CardContent>
									<Typography gutterBottom>
										<Skeleton variant="rect" width="100%" height="2rem" />
									</Typography>
									<Typography style={{ marginBottom: '16px' }}>
										<Skeleton variant="rect" width="40%" height="1rem" />
									</Typography>
									<Typography gutterBottom>
										<Skeleton variant="rect" width="100%" height="1rem" />
									</Typography>
									<Typography gutterBottom>
										<Skeleton variant="rect" width="90%" height="1rem" />
									</Typography>
									<Typography gutterBottom>
										<Skeleton variant="rect" width="80%" height="1rem" />
									</Typography>
								</CardContent>
							</Card>
						</Grid>
					))}
				</Grid>
			</Container>
			{/* <CircularProgress size={80} color="primary" disableShrink /> */}
		</div>
	);
};

export default Loader;
