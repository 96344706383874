import { Container, Grid, useMediaQuery } from '@material-ui/core';
import React, { useEffect } from 'react';
import SectionHeader from './SectionHeader';
import styled from 'styled-components';
import { trustedBy } from '../data';
import { useInView } from 'react-intersection-observer';
import { motion, useAnimation } from 'framer-motion';
import { navItem, navList, screenThreshold } from '../utils/animationVariants';

const StyledImage = styled.img`width: 200px;`;

const MotionGrid = motion(React.forwardRef((props, ref) => <Grid ref={ref} {...props} />));
const TrustedBy = () => {
	const isMdSmall = useMediaQuery((theme) => theme.breakpoints.down('md'));

	const { ref, inView } = useInView({ threshold: isMdSmall ? '0.1' : screenThreshold });
	const navListVisible = useAnimation();
	const navItemVisible = useAnimation();
	useEffect(
		() => {
			if (inView) {
				navListVisible.start('visible');
				navItemVisible.start('visible');
			}
			if (!inView) {
				navListVisible.start('hidden');
				navItemVisible.start('hidden');
			}
		},
		[ inView ]
	);
	return (
		<section id="trusted_by" ref={ref}>
			<Container>
				<SectionHeader text="Trusted by" inView={inView} />
				<br />
				<MotionGrid
					container
					justifyContent="space-around"
					spacing={3}
					initial="hidden"
					animate={navListVisible}
					variants={navList}
				>
					{trustedBy.map((companyLogo, i) => (
						<MotionGrid
							custom={i}
							initial="hidden"
							animate={navItemVisible}
							variants={navItem}
							item
							md={3}
							sm={6}
							xs={12}
							style={{ maxWidth: '250px' }}
							key={i}
						>
							<StyledImage src={companyLogo} />
						</MotionGrid>
					))}
				</MotionGrid>
			</Container>
		</section>
	);
};

export default TrustedBy;
